.line {
  fill: none;
  stroke: orange;
  stroke-width: 1.5px;
}

.axisx {
  fill: #b3b3b3;
}

.time-series {
  width: 100%;
  height: 100%;
}
