@import "../../../../scss/variables.scss";

.runbook-item__tag-wrap {
  //position: absolute;
  //left: 0%;
  //top: auto;
  //right: 0%;
  //bottom: 16px;
  //margin-left: 15px;
  //margin-top: 8px;
  //margin-right: 16px;
  z-index: 1;
  height: 23px;
  overflow: hidden;
}

.tag {
  display: inline-block;
  height: 1.5rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 0.9375rem;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.32px;

  .tag_primary {
    height: 23px;
    margin-right: 8px;
    margin-left: 0rem;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    background-color: #3d3d3d;
    color: white;
    font-size: $fontSizeMedium;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0.16px;
  }

  &__inner-wrap {
    color: white;
    font-size: $fontSizeMedium;
    line-height: 12px;
    font-weight: 400;
    letter-spacing: 0.16px;
    margin-top: 5px;
  }
}

.show-more__text {
  display: inline-block;
  height: 1.5rem;
  margin-right: 0.25rem;
  //margin-left: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border-radius: 0.9375rem;
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.32px;
  color: #fff;
}

.tag_primary-light {
  height: 23px;
  margin-right: 8px;
  margin-left: 0rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: #3d3d3d;
  color: hsla(0, 0%, 95.3%, 0.7);
  font-size: $fontSizeMedium;
  line-height: 12px;
  font-weight: 400;
  letter-spacing: 0.16px;
}
