.rf-editor {
  &-wrapper {
    height: 100%;
    &:active {
      cursor: grabbing;
      cursor: -webkit-grabbing;
      cursor: -moz-grabbing;
    }
  }
  &-hover-zone-center {
    padding-left: 60px;
    height: 40px;
    top: 8px;
    position: absolute;
    z-index: 999;
    &:hover ~ .target-node {
      opacity: 1;
    }
    &:hover ~ .rf-editor-btn-wrapper > .rf-editor-btn {
      transform: translateX(0px);
      opacity: 1;
    }
  }
  &-hover-zone-top {
    position: absolute;
    top: -5px;
    height: 12px;
    width: 100%;
    &:hover ~ .react-flow__handle {
      &-top {
        opacity: 1;
      }
    }
  }
  &-hover-zone-bottom {
    position: absolute;
    bottom: -5px;
    height: 12px;
    width: 100%;
    &:hover ~ .target-node {
      opacity: 1;
    }
    &:hover .react-flow__handle-bottom {
      width: 40px;
    }
  }
  &-default-node {
    border-radius: 6px;
    background-color: #f3f3f3;
    height: 60px;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    border: 2px solid #161616;
    &__name {
      position: absolute;
      left: 68px;
      width: 202px;
      line-height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #f3f3f3;
      pointer-events: none;

      div:nth-child(1) {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &-node-icon {
    height: 28px;
    width: 28px;
    mask-size: contain;
    mask-position: 50% 50%;
    mask-repeat: no-repeat;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }
  &-edgebutton {
    height: 22px;
    width: 22px;
    border-radius: 100%;
    transform: translate(50%, 50%);
    cursor: pointer;
    background-color: #6f6f6f;
    border: 3px solid #171717;
    background-image: url("../../../../../src/assets/images/icons/icon-close.svg");
    background-position: center;
    background-size: 6px;
    background-repeat: no-repeat;
    opacity: 0;
    pointer-events: auto;
  }
  // Styles for the delete and duplicate buttons as well as their parent div
  &-btn {
    height: 24px;
    width: 24px;
    border-radius: 100%;
    transform: translateX(34px);
    transition: opacity 0.15s ease-in-out, transform 0.1s ease-in-out;
    transition-delay: 0.25s;
    background: #555;
    cursor: pointer;
    margin-left: 5px;
    opacity: 0;
    border: none;
    &__delete {
      background-image: url("../../../../../src/assets/images/icons/icon-trash-new.svg");
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
      &:hover {
        background-color: #ff5b5b;
      }
    }
    &__clone {
      background-image: url("../../../../../src/assets/images/icons/icon-copy-new.svg");
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
      &:hover {
        background-color: #616161;
      }
    }
    &__edit {
      background-image: url("../../../../../src/assets/images/icons/icon-edit-new.svg");
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
      &:hover {
        background-color: #616161;
      }
    }

    &-wrapper {
      position: absolute;
      left: -4.5rem;
      padding-right: 10px;
      top: 17px;
      overflow: hidden;
      &-trigger {
        left: -2.5rem;
      }
      &:hover > .rf-editor-btn {
        transform: translateX(0px);
        opacity: 1;
      }
    }
  }
}
.rf-editor-wrapper .react-flow {
  & .selected {
    .rf-editor-default-node {
      border-radius: 5px;
      border: 2px solid #181818;
      box-shadow: 0px 0px 0px 3px #a07ff0;
      &:active {
        cursor: grabbing;
      }
    }
  }
  &__renderer {
    z-index: 3;
  }
  &__edge {
    &-path {
      stroke-width: 2px;
      stroke: #6f6f6f;
      pointer-events: none;
      &:hover {
        stroke: #6f6f6f;
      }
      &-outer {
        stroke-width: 12px;
        stroke: #a07ff0;
        fill: none;
        opacity: 0;
      }
    }
    &.selected {
      & .react-flow__edge-path {
        stroke: #a07ff0;
        stroke-width: 2px;
      }
      & .react-flow__edge-path-outer {
        stroke: #a07ff0;
        opacity: 0.25;
      }
      & .rf-editor-edgebutton {
        opacity: 1;
      }
    }
    &:hover {
      & .rf-editor-edgebutton {
        opacity: 1;
      }
      & .react-flow__edge-path-outer {
        opacity: 0.25;
      }
    }

    &__connection {
      cursor: pointer;
      pointer-events: auto;
    }
  }
  &__handle {
    height: 18px;
    width: 18px;
    border-radius: 100%;
    cursor: pointer;
    background-image: url("../../../../../src/assets/images/icons/drag-icon2.svg");
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    &:hover {
      opacity: 1;
    }
    &-bottom {
      bottom: -9px;
    }
    &-top {
      top: -9px;
      visibility: hidden;
    }
    &.connectable {
      cursor: pointer;
    }
  }
  &__controls {
    right: 16px;
    bottom: 16px;
    left: auto;
    z-index: 3;
    &-button {
      background: #555;
      border-bottom: 1px solid #171717;
      border-top: 1px solid #171717;
      &:hover {
        background: #616161;
      }
      &:focus {
        box-shadow: inset 0px 0px 0px 2px #f3f3f3;
      }
      & svg {
        fill: #f3f3f3;
      }
    }
  }
}
.edgebutton-foreignobject {
  pointer-events: none;
}

.inport-enabled {
  .react-flow__handle-top {
    visibility: visible;
    top: -9px;
  }
  .rf-editor-btn-wrapper {
    visibility: hidden;
  }
}

.step-id {
  color: #8c8c8c;
  font-size: 12px;
}

.option-hover {
  .rf-editor-default-node {
    box-shadow: 0px 0px 0px 3px #faa666;
  }
}
