@import "../../../../scss/variables.scss";

.editor {
  &-menu {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 44px;
    height: auto;
    background-color: #282828;
    border-right: 1px solid #171717;
    justify-content: space-between;
    &-top {
      display: flex;
      flex-direction: column;
    }
    &-bottom {
      display: flex;
    }
    &-item {
      height: 44px;
      width: 44px;
      opacity: 1;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      cursor: pointer;
      position: relative;
      &-icon {
        margin-left: 13px;
        margin-top: 12px;
        width: 18px;
        height: 18px;
        &-image {
          opacity: 0.6;
          &:hover {
            opacity: 1;
          }
        }
        &-logo {
          opacity: 0.6;
        }
        &-svg {
          & g {
            fill: $svgFill1;
          }
          & path {
            fill: $svgFill1;
          }
          & polygon {
            fill: $svgFill1;
          }
          &:hover {
            & g {
              fill: $svgFill2;
            }
            & path {
              fill: $svgFill2;
            }
            & polygon {
              fill: $svgFill2;
            }
          }
        }
      }
      &-active {
        border-top-color: $primaryBackground;
        border-bottom-color: $primaryBackground;
        background-color: $tertiaryBackground;
        width: 44px;
      }
    }
  }
}
.editor-menu-item-active > .editor-menu-item-icon-image {
  opacity: 1;
}

.help-overlay {
  position: fixed;
  height: 100%;
  width: calc(100% - 44px);
  right: 0;
  top: 0;
  background: #171717;
  opacity: 0;
  z-index: 4;
}
.help {
  position: relative;
  &-dropdown {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 3.5rem;
    bottom: 0.5rem;
    z-index: 10;
    box-shadow: 5px 0px 10px 0px rgba(0, 0, 0, 0.15);

    &-carrot {
      position: absolute;
      left: -6px;
      top: 100px;
      bottom: 5px;
      z-index: -1;
      width: 13px;
      height: 13px;
      background-color: #3d3d3d;
      transform: rotate(45deg);
    }
    &-option {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #2e2e2e;
      background-color: #3d3d3d;
      height: 2.5rem;
      width: 11rem;
      font-size: 13px;
      padding: 0 12px;
      cursor: pointer;
      &:hover {
        background-color: #535353;
        color: #f3f3f3;
      }
      & > a {
        color: inherit;
      }
      &-icons {
        width: 16px;
        height: 16px;
        margin-right: 6px;
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        &-reset {
          background-image: url("https://assets.website-files.com/5d7fdec85e3a80dddda38d90/5f721d78d604ca5fc94c1cfd_reset-icon.svg");
        }
        &-support {
          background-image: url("https://assets.website-files.com/5d7fdec85e3a80dddda38d90/5f721c98340e0efbff617eb7_Bulb.svg");
        }
        &-documentation {
          background-image: url("../../../../../src/assets/images/icons/icon-docs.svg");
        }
      }
    }
    a {
      color: inherit;
    }
  }
}
