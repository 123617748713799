.ti {
  padding: 20px 16px;
  &-wrap {
    color: #f3f3f3;
    padding: 16px;
    background-color: #555;
    display: flex;
    flex-direction: column;
    &-header {
      margin-bottom: 16px;
      color: #f3f3f3;
      font-weight: 700;
    }
    &-container {
      margin-bottom: 20px;
      color: #f3f3f3;
      font-weight: 300;
    }
    ul {
      color: #f3f3f3;
      margin-bottom: 10px;
      padding-left: 20px;
      li {
        display: inline-block;
        margin-bottom: 8px;
        &::before {
          content: "•";
          color: #f3f3f3;
          margin-right: 5px;
        }
      }
    }
    .learn {
      color: #65adff;
      span {
        padding-right: 5px;
      }
      &:hover {
        color: #86bfff;
      }
    }
  }
}
