.editor-right-panel {
  &-first-input {
    margin-bottom: 24px;
  }
  &-scroll {
    overflow-y: auto;
    height: calc(100vh - 126px);
  }
  &-field-label {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: hsla(0, 0%, 95.3%, 0.9);
    font-size: 12px;
    font-weight: 500;
  }
  &-details {
    color: rgba(243, 243, 243, 0.9);
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    padding: 1rem;
    overflow-y: auto;
  }
  &-previous-container {
    margin: 24px 0 0 24px;
    padding: 0 0px 24px 24px;
    border-style: none none none solid;
    border-width: 1px 1px 1px 2px;
    border-color: #363636 #363636 #363636 #555;
    background-color: transparent;
  }
  &-warning {
    margin: 0 16px;
    background: #616161;
    border: 1px solid #353535;
    box-shadow: inset 3px 0px 0px 0px #fcd33a;
    padding: 16px;
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;
    & > div > .editor-right-panel-field-label {
      color: #f3f3f3;
      font-weight: 400;
      font-size: 13px;
    }
    & > div > .warning-link {
      font-size: 13px;
      line-height: 17px;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &-info {
    margin: 0 16px;
    background: #616161;
    border: 1px solid #353535;
    box-shadow: inset 3px 0px 0px 0px #fcd33a;
    padding: 16px;
    margin-bottom: 2rem;
    display: flex;
    align-items: flex-start;
    & > div > .editor-right-panel-field-label {
      color: #f3f3f3;
      font-weight: 400;
      font-size: 13px;
    }
  }
  &-action-node-output {
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed #7c7c7c;
    background-color: transparent;
    transition: all 70ms ease;
    color: hsla(0, 0%, 95.3%, 0.5);
    font-size: 14px;
    cursor: pointer;
    &:hover {
      background-color: rgba(97, 97, 97, 0.5);
      color: #f3f3f3;
    }
  }
}
