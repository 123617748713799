.modal-cover {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsla(0, 0%, 9%, 0.5);
  box-sizing: border-box;
}

.modal-container {
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 768px;
  min-width: 500px;
  background-color: #282828;
  color: #f3f3f3;
  top: -5vh;
  opacity: 0;
  margin: 0 auto;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
}

.background-content {
  height: 100%;
  overflow: auto;
}

.modal-header {
  width: 75%;
  margin-bottom: 0.5rem;
  padding-top: 1rem;
  padding-left: 1rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #f3f3f3;
  font-size: 1.25rem;
  line-height: 1.625rem;
  letter-spacing: 0px;
}

.modal-close {
  position: fixed;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: auto;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  padding: 0.75rem;
  border: 0px;
  background-color: transparent;
  background-image: url("../../../assets/images/icons/icon-close.svg");
  background-position: 50% 50%;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  -webkit-transition: all 110ms ease;
  transition: all 110ms ease;
  max-width: 100%;
  display: inline-block;
  cursor: pointer;

  &:hover {
    background-color: #353535;
  }
  &:focus {
    box-shadow: inset 0px 0px 0px 2px #f3f3f3;
  }
  &:active {
    background-color: #565656;
  }
}

.modal-content-container {
  overflow: auto;
  position: relative;
  width: 100%;
}

.modal-content {
  margin-bottom: 3rem;
  padding: 1rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  line-height: 1.25rem;
  max-width: 768px;
  width: 100%;

  & span.error {
    color: #ff5b5b;
    margin-top: 10px;
    display: block;
  }
}

.modal-slide-in {
  animation: _modal-slide 0.3s ease-in-out;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.modal-footer {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 4rem;
  min-height: 4rem;
  background-color: transparent;
  align-items: center;
  &__editor {
    height: auto;
    background-color: transparent;
    min-height: 0px;
  }
}

.modal-buttons-footer {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  height: 4rem;
  &__editor {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

@keyframes _modal-slide {
  100% {
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
  }
}
