.runbook-item {
  height: 196px;
  position: relative;
  max-width: 730px;
  min-width: 320px;
  display: flex;
  background-color: #282828;
  a {
    width: 100%;
  }
}

.runbook-item__container {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 730px;
  min-width: 320px;
}

.runbook-item__meta {
  padding: 24px;
  position: relative;
  padding-right: 54px;

  h2 {
    margin-top: 0px;
    font-weight: 400;
    overflow: hidden;
    max-height: 44px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.runbook-item__description {
  color: #bebebe;
  font-weight: 400;
  font-size: 13px;
  max-width: 650px;
  height: 36px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.runbook-item__title {
  margin-bottom: 6px;
  color: #fff;
  font-size: 17px;
  line-height: 22px;
  font-family: IBMPlexSans-semibold, sans-serif;
}

.runbook-item__info {
  color: hsla(0, 0%, 95.3%, 0.6);
  margin-bottom: 12px;
  font-size: 12px;
  display: flex;
}

.fill-text {
  visibility: hidden;
  max-height: 1px;
  overflow: hidden;
}

.runbook-meta__wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
}
