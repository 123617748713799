.dt {
  display: flex;
  flex-direction: column;
}

.react-datepicker {
  background-color: #555;
  border: 1px solid #303030;
  border-radius: 0px;
  padding: 4px 4px 8px 4px;
  color: #f3f3f3;
  box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.25);
  &-popper {
    z-index: 10;
  }
  &__day-name {
    height: 32px;
    width: 32px;
  }
  &__input-time-container {
    display: none;
  }
  &__day--selected,
  &__day--keyboard-selected {
    background-color: #934eff;
    border-radius: 0;
    &:hover {
      background-color: #f0f0f0;
    }
    &:focus {
      outline: 0;
      border-radius: 0;
      border: none;
    }
  }
  &__day {
    color: #f3f3f3;
    height: 32px;
    width: 32px;
    padding-top: 3px;
    &:hover {
      color: #2c2c2c;
      border-radius: 0;
    }
    &:focus {
      outline: 0;
    }
  }
  &__day--outside-month {
    opacity: 0.5;
  }
  &__day-name {
    color: #f3f3f3;
  }

  &__current-month {
    color: #f3f3f3;
    font-weight: 400;
    font-size: 14px;
    height: 32px;
    margin-top: 4px;
  }
  &__header {
    background-color: #555;
    border-bottom: 0px;
  }
  &__navigation {
    height: 32px;
    width: 32px;
    &:hover {
      background-color: #6f6f6f;
    }
    &--previous {
      border: none;
      background-image: url("./icon-datepicker-prev.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 8px;
    }
    &--next {
      border: none;
      background-image: url("./icon-datepicker-next.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 8px;
    }
  }
  &__input-container {
    & > input {
      height: 40px;
      background: #555;
      width: 100%;
      box-sizing: border-box;
      min-width: 352px;
      border: 0px solid #303030;
      color: #f3f3f3;
      line-height: 20px;
      font-size: 14px;
      padding-left: 10px;
      cursor: pointer;
      &.error {
        border: 2px solid #f55;
      }
    }
  }
}
