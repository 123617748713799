.filters {
  &-header {
    font-size: 12px;
    font-weight: 400;
  }
  &-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    max-height: 10rem;
  }
  &-add {
    width: 480px;
    z-index: 99;
    height: 100%;
    padding: 16px;
    padding-right: 22px;
    padding-top: 16px;
    background-color: #3d3d3d;
    position: fixed;
    top: 0px;
    right: 0px;
    &-header {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 8px;
      font-size: 16px;
      color: #f3f3f3;
      font-weight: 500;
    }
    &-input {
      height: 40px;
      border: none;
      background-color: #555 !important;
      color: #f3f3f3;
      font-size: 14px;
      margin-top: 0px;
      padding-left: 16px;
      &:hover {
        background-color: #616161 !important;
      }
      &:focus {
        border-color: transparent;
        background-color: #616161;
        box-shadow: inset 0 0 0 2px #f3f3f3;
      }
      &.error {
        box-shadow: inset 0 0 0 2px #fa4d56;
      }
      &.error:focus {
        box-shadow: inset 0 0 0 2px #f3f3f3;
      }
    }
  }
  &-value-error {
    color: #ff5b5b;
    font-size: 12px;
  }
  &-manage {
    &-container {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      position: relative;
      max-height: 10rem;
    }
  }
}

.clearfix {
  border-bottom: 1px solid #292929;
  margin: 0 -1.4rem 1rem -1rem;
}

.close-icon {
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  &:hover {
    background: #616161;
  }
  &:active {
    background: #393939;
  }
  &:focus {
    box-shadow: inset 0px 0px 0px 2px #f3f3f3;
  }
}
.close-img {
  pointer-events: none;
}
