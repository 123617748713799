.notifications {
  margin-top: 24px;
  padding: 24px 24px 8px;
  border: 2px solid #3d3d3d;
  .wrapper {
    width: 40%;
    display: flex;
    flex-direction: column;
    height: 220px;
    .save-btn {
      height: 40px;
      width: 125px;
    }
  }
  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
