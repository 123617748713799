@import "../../scss/variables.scss";
.lm {
  &-version {
    margin-bottom: 20px;
    font-size: 12px;
    opacity: 0.7;
    padding-left: 15px;
  }
}

.menu-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

ul.nav-menu {
  background-color: $secondaryBackground;
  margin-bottom: 0px;
  padding-left: 0px;
  font-size: $fontSizeLarge;
  color: $secondaryFontColor;
  letter-spacing: 0.16px;
  list-style-type: none;
}
.nav-menu__item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.nav-link {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding-left: 45px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: 3px solid transparent;
  -webkit-transition: all 90ms ease;
  transition: all 90ms ease;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
  color: $secondaryFontColor;
  &:hover {
    color: #fff;
  }
  &-support {
    padding-left: 16px;
  }
}

.w--not-selected {
  border-left: 3px solid transparent;
  &:hover {
    background-color: $tertiaryBackground;
  }
}
.w--current {
  border-left: 3px solid $primaryAccentColor;
  background-color: $tertiaryBackground;
  opacity: 1;
  color: $secondaryFontColor;
  font-weight: 400;
  text-decoration: none;
}

.icon {
  margin-right: 10px;
  background-position: 7% 50%;
  background-size: 18px 17px;
  background-repeat: no-repeat;
  opacity: 1;

  &__support {
    background-position: 90% 50%;
    background-size: 12px;
  }

  &__runbooks {
    background-image: url(../../assets/images/icons/icon-runbooks.svg);
  }

  &__snippets {
    background-image: url(../../assets/images/icons/icon-snippets.svg);
  }

  &__tasks {
    background-image: url(../../assets/images/icons/icon-incident.svg);
  }

  &__executions {
    background-image: url(../../assets/images/icons/icon-executions.svg);
  }

  &__users {
    background-image: url(../../assets/images/icons/icon-users.svg);
  }

  &__settings {
    background-image: url(../../assets/images/icons/icon-settings.svg);
  }
  &__calender {
    background-image: url(../../assets/images/icons/icon-calendar.svg);
  }
  &__external {
    background-image: url(../../assets/images/icons/icon-external.svg);
  }
}

.bottom-section {
  margin-bottom: 5rem;
}

.divider-wrap {
  width: 100%;
  height: 1px;
  margin-top: 16px;
  margin-bottom: 16px;
  padding-right: 16px;
  padding-left: 16px;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #3d3d3d;
}

.user-details {
  padding: 0.9rem 1rem;
  .username {
    flex-basis: 90%;
    color: #f3f3f3;
    font-weight: 400;
    letter-spacing: 0.16px;
    font-size: 15px;
  }
  .email {
    font-size: 14px;
    font-weight: 400;
    margin-top: 0.3rem;
    letter-spacing: 0.16px;
    opacity: 0.5;
    color: #f3f3f3;
  }
}
