.string-transform {
  &-input {
    padding-bottom: 0px;
    margin-bottom: -15px;
  }
  &-hidden {
    display: none;
  }
}

.dropdown-tick-icon {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  filter: grayscale(1);
  filter: brightness(500%);
  transform: translate(0%, 30%);
  background-size: contain;
  background-repeat: no-repeat;
}

.visibility-hidden {
  visibility: hidden;
}

.operations-wrapper {
  width: calc(100% - 36px);
  border-left: 2px solid #555;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: auto;
  padding-bottom: 1rem;
  margin-bottom: 0.5rem;
}

.transform-output {
  border-style: solid;
  border-width: 1px;
  border-color: #2b2b2b;
  background-color: #363636;
  padding: 1.2rem;
  display: flex;
  align-items: center;
  min-height: 58px;
  color: #f3f3f3;
  font-family: Ibmplexmono, sans-serif;
}

.mt-n3 {
  margin-top: -1.5rem;
}
