.tour {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: hsla(0, 0%, 95.3%, 0.9);
    min-height: 10rem;
    &-header,
    &-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-btn {
        height: 2rem;
        width: 5rem;
        color: #f3f3f3;
        font-size: 12px;
        border: none;
        cursor: pointer;
        opacity: 1;
        &:hover {
          opacity: 0.9;
        }
        &-prev {
          background-color: #5e5e5e;
        }
        &-next {
          background-color: #6300ff;
        }
      }
    }
    &-header {
      font-size: 15px;
      line-height: 24px;
      font-weight: 400;
    }
    &-content {
      font-size: 13px;
      font-weight: 400;
      letter-spacing: 0.16px;
      line-height: 20px;
    }
  }
}
