@import "../../scss/variables.scss";

/* width */

/* Track */
::-webkit-scrollbar-track {
  background: $canvasPrimaryBackground;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $canvasPrimaryAccent;
}

/* Corner color */
::-webkit-scrollbar-corner {
  background: #303030;
}

// .runbook {
//   &-title {
//     display: -webkit-box;
//     display: -webkit-flex;
//     display: -ms-flexbox;
//     display: flex;
//     width: auto;
//     height: 40px;
//     padding-right: 16px;
//     padding-left: 16px;
//     align-items: center;
//     border-right: 1px solid $canvasBorder1;
//     border-left: 1px solid $canvasBorder1;
//     transition: all 70ms ease;
//     color: $secondaryFontColor;
//     font-size: $fontSizeMediumLarge;
//     font-weight: 400;
//     letter-spacing: 0.16px;
//     text-decoration: none;
//     cursor: pointer;

//     &:hover {
//       background-color: $tertiaryBackground;
//     }
//   }
// }

.runbook-title > .link-underline {
  padding-right: 8px;
}

.d-flex {
  display: flex;
  &-sb {
    display: flex;
    justify-content: space-between;
  }
}

.editor {
  box-sizing: border-box;

  &-right-panel-resize {
    height: 100%;
    width: 1%;
    max-width: 3px;
    position: absolute;
    left: 0px;
    z-index: 100;
    cursor: ew-resize;
    &:active {
      background: yellow;
    }
  }

  /* editor-is-fetching */
  &-is-fetching {
    margin-top: 5px;
    font-style: italic;
    color: $canvasPrimaryAccent;
  }

  /* editor-gear, editor-gear-icon */
  &-gear {
    &-icon {
      display: -webkit-box;
      width: 24px;
      height: 24px;
      flex: 0 0 auto;
      background-image: url("../../assets/images/icons/gear-icon__filled.svg");
      background-position: 50% 50%;
      background-size: 14px 14px;
      background-repeat: no-repeat;
      opacity: 0.6;
      -webkit-transform: rotate(-90deg);
      -ms-transform: rotate(-90deg);
      transform: rotate(-90deg);
      cursor: pointer;
      &-wrap {
        position: absolute;
        top: 16px;
        right: 40px;
        z-index: 2;
        display: flex;
        width: 24px;
        height: 24px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
      }
      &:hover {
        opacity: 1;
        background-color: #616161;
      }
    }
  }
  // &-page-wrap {
  //   position: relative;
  //   overflow: hidden;
  //   width: 100vw;
  //   height: 100vh;
  // }

  &-dropdown {
    display: flex;
    padding: 0px 12px;
    justify-content: flex-start;
    border-style: solid;
    border-width: 1px;
    border-color: $canvasPrimaryBackground;
    background-color: $tertiaryAccentColor;
    transition: all 70ms ease;
    color: $secondaryFontColor;
    font-size: $fontSizeMedium;
    font-weight: 400;
    box-sizing: border-box;
    top: 2px;

    /* editor-dropdown-open */
    &-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 16px;
      right: 16px;
      min-width: 250px;
      border-style: solid;
      border-width: 1px;
      border-color: #efefef;
      background-color: #555;
      box-shadow: 5px 0 10px 0 rgba(0, 0, 0, 0.15);
      color: $secondaryFontColor;
      font-size: $fontSizeMedium;
      font-weight: 400;
      align-content: center;
      z-index: 200;
      padding: 0px;
    }
    & .label:hover {
      color: #f3f3f3;
    }
    & .label {
      cursor: pointer;
    }
    &-link {
      position: relative;
      display: flex;
      align-content: center;
      transition: all 70ms ease;
      color: $secondaryFontColor;
      font-size: $fontSizeMediumLarge;
      padding-left: 14px;
      padding-top: 12px;
      font-weight: 400;
      letter-spacing: 0.16px;
      height: 40px;
      cursor: pointer;
      width: 100%;
      &:hover {
        background-color: #6f6f6f;
      }
      &:last-child {
        border-bottom: none;
      }
      &-wrap {
        padding: 0px;
        margin: 0px;
        height: 100%;
        height: 40px;
        color: rgba(243, 243, 243, 0.8);
        padding-left: 12px;
        &:hover {
          background-color: #6f6f6f;
          color: rgba(243, 243, 243, 1);
        }
      }
      &-label {
        padding: 0px 0px 0px 26px;
        margin: 0px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 13px;
        & > .checkmark {
          top: 11px;
        }
      }
    }

    &-mask {
      position: relative;
      top: -2px;
      width: 100%;
      height: 4px;
      background-color: $tertiaryAccentColor;
    }

    &-section-title {
      display: flex;
      height: 26px;
      padding-left: 8px;
      align-items: center;
      background-color: $accordionPrimaryBackground;
      color: $accordionAccentColor;
      font-size: $fontSizeSmall;
      font-weight: 400;
      letter-spacing: 0.16px;
      text-transform: uppercase;
    }
  }

  /* END OF editor-dropdown */

  &-node-name {
    font-size: $fontSizeHeadingSmall;
    font-weight: 400;
    padding-left: 12px;
    color: #5ca8ff;
    height: 44px;
    display: flex;
    align-items: center;
  }

  &-toolbar {
    // &-wrap {
    //   position: absolute;
    //   left: 41px;
    //   top: 0%;
    //   right: 0%;
    //   bottom: auto;
    //   display: -webkit-box;
    //   display: -webkit-flex;
    //   display: -ms-flexbox;
    //   display: flex;
    //   height: 40px;
    //   padding-right: 384px;
    //   -webkit-box-pack: justify;
    //   -webkit-justify-content: space-between;
    //   -ms-flex-pack: justify;
    //   justify-content: space-between;
    //   background-color: $secondaryAccentColor;
    //   z-index: 5;
    // }
    &-right {
      &-container {
        display: flex;
        flex-direction: column;
      }
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;

      & .workflow-run-dropdown {
        background: $btnSecondaryBackground;
      }

      & .dropdown-list {
        overflow-y: hidden;
      }
    }
  }
  /* END OF TOOLBAR */
  &-detail {
    &-wrap {
      position: absolute;
      left: auto;
      top: 0%;
      right: 0%;
      bottom: 0%;
      width: 480px;
      border-left: 1px solid $primaryBackground;
      background-color: $tertiaryBackground;
      z-index: 3;
    }
    &-tab-wrap {
      & > div {
        height: 100%;
        width: 100%;
        justify-content: center;
        justify-items: center;
        align-items: center;
        display: flex;
      }
    }
    &-active-tab {
      border: 1px solid transparent;
      border-top: none;
      background: transparent;
      min-height: 44px;
      width: 33.33%;
      color: #f3f3f3;
      justify-items: center;
      justify-content: center;
    }
    &-inactive-tab {
      background: $secondaryAccentColor;
      border: 1px solid $canvasBorder1;
      border-top: none;
      min-height: 40px;
      width: 33.33%;
      &:hover {
        color: #f3f3f3;
      }
    }
    /* editor-detail-panel */
    &-panel {
      position: relative;
      padding: 16px;
      padding-bottom: 0;
      display: flex;
      margin-bottom: 20px;
      &-row {
        flex-direction: row;
      }
      /* editor-detail-panel-icon */
      &-icon {
        width: 15px;
        height: 15px;
        margin-top: 2px;
        margin-right: 8px;
        background-position: 50% 50%;
        background-size: 12px;
        background-repeat: no-repeat;
        opacity: 0.8;
        &-select {
          background-image: url("../../assets/images/icons/icon-select.svg");
        }
      }
      &-title {
        position: relative;
        display: flex;
        width: 100%;
        height: 48px;
        padding-right: 12px;
        padding-left: 12px;
        align-items: center;
        border-bottom: 1px solid $secondaryBackground;
        color: $secondaryFontColor;
        font-size: $fontSizeMediumLarge;
        line-height: 20px;
        font-weight: 400;
        letter-spacing: 0.16px;
      }
      &-column {
        flex-direction: column;
      }
      &-new {
        padding: 0px 16px 20px;
      }
    }

    /* editor-placeholder */
    &-placeholder {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      border-top: 1px solid $secondaryBackground;
      &-icon {
        width: 40px;
        height: 40px;
        background-image: url("../../assets/images/icons/icon-hand.svg");
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
      }
      &-text {
        margin-top: 20px;
        opacity: 1;
        color: $secondaryFontColor;
        font-size: $fontSizeMediumSmall;
        line-height: 16px;
        font-weight: 400;
        text-align: center;
        letter-spacing: 0.16px;
      }
      &-inner-wrap {
        display: flex;
        width: 60%;
        height: 30%;
        margin-top: 20%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 0 0 auto;
      }
    }
  }
  /* END OF DETAIL */

  &-accordion {
    &-label {
      display: flex;
      width: 100%;
      height: 36px;
      padding-right: 12px;
      padding-left: 16px;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-align: center;
      align-items: center;
      background-color: $accordionPrimaryBackground;
      color: hsla(0, 0%, 95.3%, 0.7);
      font-size: $fontSizeMediumSmall;
      line-height: 20px;
      font-weight: 400;
      text-transform: uppercase;
      cursor: pointer;
      z-index: 2;
    }
    &-row {
      position: relative;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      width: 100%;
      height: 40px;
      padding-right: 12px;
      padding-left: 16px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-bottom: 1px solid $accordionBorderColor1;
      -webkit-transition: all 90ms ease;
      transition: all 90ms ease;
      font-family: IBMPlexSans-Regular, sans-serif;
      color: $accordionFontColor;
      font-size: $fontSizeMediumLarge;
      font-weight: 400;
      letter-spacing: 0.16px;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        background-color: $accordionAccent;
        color: $secondaryFontColor;
      }

      &-active {
        background-color: $accordionAccent;
        color: $secondaryFontColor;
      }

      &-icon {
        width: 16px;
        height: 14px;
        margin-right: 10px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        background-image: url("../../assets/images/icons/icon-versions.svg");
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0.7;
      }

      &__dropdown {
        position: absolute;
        right: 12px;
        width: 24px;
        height: 24px;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        padding: 0px;
        background-color: transparent;
        border: none;
        -webkit-transition: opacity 70ms ease;
        transition: opacity 70ms ease;
        cursor: pointer;
        &:active {
          box-shadow: inset 0px 0px 0px 2px #f3f3f3;
        }
        &-icon {
          background-image: url("../../assets/images/icons/gear-icon__filled.svg");
          opacity: 0.4;
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          background-size: 14px;
          &:hover {
            opacity: 1;
          }
        }
        &-link {
          display: flex;
          height: 2.5rem;
          padding: 0px 16px;
          flex-direction: row;
          align-items: center;
          opacity: 1;
          -webkit-transition: all 70ms ease;
          transition: all 70ms ease;
          color: $secondaryFontColor;
          font-size: $fontSizeMediumLarge;
          cursor: pointer;
          &:hover {
            background-color: #616161;
          }
        }
        &-delete {
          &:hover {
            background-color: $deleteHoverBackground;
          }
        }
      } //end of __dropdown
    }
  }

  /* editor-tags */
  &-tags {
    display: inline-block;
    height: 1.5rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-radius: 0.9375rem;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: 0.32;

    &-wrapper {
      display: flex;
      padding-bottom: 10px;
      align-items: center;
    }
    &-filter {
      margin-right: 8px;
      margin-left: 0rem;
      padding-right: 0.125rem;
      border-style: none;
      border-width: 1px;
      border-color: $secondaryAccentColor;
      background-color: $accordionAccent;
      color: $secondaryFontColor;
      font-size: $fontSizeMediumSmall;
      cursor: pointer;
    }
    &-inner-wrapper {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
    }
  }
  &-icon-error {
    position: absolute;
    left: auto;
    top: 8px;
    right: 4px;
    bottom: auto;
    z-index: 2;
    width: 14px;
    height: 14px;
    background-image: url("../../assets/images/icons/icon-error.svg");
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
  }
}

textarea {
  resize: none;
}

/* END OF EDITOR */

.editor-design-area-container {
  width: 100%;
  height: 100%;
}

/* DIAGRAM */
.srd-diagram {
  position: absolute;
  right: 0;
  left: 0px;
  flex-grow: 1;
  display: flex;
  cursor: move;
  overflow-y: auto;
  overflow-x: auto;
  justify-content: center;
  justify-items: center;
  width: 100%;
  max-width: 2000px;
  box-sizing: border-box;
  min-height: 80%;
  margin-left: 44px;
}

.srd-diagram__selector {
  position: absolute;
  background-color: rgba(0, 192, 255, 0.2);
  border: solid 2px $primaryNodeColor;
}

.srd-link-layer {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-origin: 0 0;
  overflow: visible !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.srd-node-layer {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}

.srd-node {
  position: absolute;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  user-select: none;
  cursor: move;
  pointer-events: all;
}

.srd-node--selected > * {
  border-color: $primaryNodeColor !important;
}

.srd-port {
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background: $portBackground;
}

.srd-port:hover,
.srd-port.selected {
  background: $portSelectedColor;
}

.srd-default-node {
  background-color: $defaultNodeBackground !important;
  border-radius: 100px;
  font-family: IBMPlexSans-Medium, sans-serif;
  color: $nodeFontColor;
  border: solid 2px $nodeBorderColor;
  overflow: visible;
  font-size: $fontSizeMediumSmall;
  width: 60px;
  height: 60px;
}

.srd-default-node__title {
  display: flex;
  white-space: nowrap;
  position: relative;
  right: -60px;
}

.srd-default-node__title > * {
  align-self: center;
}

.srd-default-node__title .fa {
  padding: 5px;
  opacity: 0.2;
  cursor: pointer;
}

.srd-default-node__title .fa:hover {
  opacity: 1;
}

.srd-default-node__name {
  flex-grow: 1;
  padding: 5px 5px;
}

.srd-default-node__ports {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  top: -23px;
  justify-content: space-between;
  .srd-default-node__out {
    display: flex;
    justify-content: flex-end;
  }
}

.srd-default-node__in,
.srd-default-node__out {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.srd-default-port {
  display: flex;
  margin-top: 1px;
  justify-content: center;
}

.srd-default-port > * {
  align-self: center;
}

.srd-default-port .name {
  display: none;
  padding: 0 5px;
}

.srd-default-ports .srd-default-label {
  background: $defaultPortsBackground;
  border: 1px solid $defaultPortsBorderColor;
  border-radius: 4px;
  color: $defaultPortsFontColor;
  display: inline-block;
  font-size: smaller;
  padding: 5px;
}

@keyframes dash {
  from {
    stroke-dashoffset: 24;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.srd-default-link path {
  fill: none;
  pointer-events: all;
}

.srd-default-link--path-selected {
  stroke: $primaryNodeColor !important;
  stroke-dasharray: 10, 2;
  animation: dash 1s linear infinite;
}

.srd-default-link__label {
  pointer-events: none;
}

.srd-default-link__label > div {
  display: inline-block;
  position: absolute;
}

.srd-default-link__point {
  fill: $defaultLinkBackground;
}

.srd-default-link--point-selected {
  fill: $primaryNodeColor;
}

.srd-demo-workspace {
  background: $srdDemoBackground;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.srd-demo-workspace__toolbar {
  padding: 5px;
  display: flex;
  flex-shrink: 0;
}

.srd-demo-workspace__toolbar button {
  background: $srdPrimaryBackground;
  font-size: $fontSizeLarge;
  padding: 5px 10px;
  border: none;
  color: $srdButtonFontColor;
  outline: none;
  cursor: pointer;
  margin: 2px;
  border-radius: 3px;
}

.srd-demo-workspace__toolbar button:hover {
  background: $primaryNodeColor;
}

.srd-demo-workspace__content {
  flex-grow: 1;
  height: 100%;
}

.docs-preview-wrapper {
  background: $srdPrimaryBackground;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.neurops-runbook-canvas {
  height: calc(100vh - 62px);
  background-color: $srdPrimaryBackground !important;
  background-image: linear-gradient(
      0deg,
      transparent 24%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(255, 255, 255, 0.05) 76%,
      transparent 77%,
      transparent
    ),
    linear-gradient(
      90deg,
      transparent 24%,
      rgba(255, 255, 255, 0.05) 25%,
      rgba(255, 255, 255, 0.05) 26%,
      transparent 27%,
      transparent 74%,
      rgba(255, 255, 255, 0.05) 75%,
      rgba(255, 255, 255, 0.05) 76%,
      transparent 77%,
      transparent
    );
  background-size: 50px 50px;
  &.execution {
    margin-left: 255px;
  }
}

.neurops-runbook-canvas .pointui {
  fill: rgba(255, 255, 255, 0.5);
}

/* FORM STUFF */
.builder-form-label {
  color: hsla(0, 0%, 95.3%, 0.7);
  font-size: $fontSizeMediumSmall;
  font-weight: 400;
  padding-bottom: 5px;
}

.rule {
  position: relative;
  &-input {
    height: 40px;
    background: $ruleInputBackground;
    width: 100%;
    box-sizing: border-box;
    min-width: 352px;
    border: 0px solid $canvasPrimaryBackground;
    color: #f3f3f3;
    line-height: 20px;
    font-size: $fontSizeLarge;
    padding-left: 16px;
    cursor: default;
    &:focus {
      border-color: transparent;
      background-color: #616161;
      box-shadow: inset 0 0 0 2px #f3f3f3;
      border: none;
    }
    &::-webkit-input-placeholder {
      color: #bebebe;
    }
    &:hover {
      background-color: #616161;
    }
    &-error {
      border: 2px solid #f55;
    }
    &__cursor-na {
      cursor: not-allowed;
      &:hover {
        background: $ruleInputBackground;
      }
    }
  }
  &-list {
    margin-bottom: 20px;
    &-wrap {
      width: 100%;
      height: 32px;
      border-style: solid;
      border-width: 1px;
      border-color: $canvasPrimaryBackground;
      background-color: $tertiaryAccentColor;
      display: table;
      position: relative;
    }

    /* rule-list-item */
    &-item {
      color: $secondaryFontColor;
      font-size: $fontSizeMedium;
      margin-left: 10px;
      display: table-cell;
      vertical-align: middle;
      padding-left: 12px;
    }
    &-empty {
      position: relative;
      left: 1px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-style: dashed;
      border-color: $ruleListBorderColor;
      background-color: transparent;
      transition: all 70ms ease;
      color: $ruleListFontColor;
      font-size: $fontSizeMedium;
      cursor: pointer;
      margin-top: -10px;
      &:hover {
        background-color: $ruleListHoverBackground;
        color: $secondaryFontColor;
      }
    }
  }
  &-actions {
    position: absolute;
    right: 12px;
    top: 0px;
    display: flex;
    width: 80px;
    height: 32px;
    justify-content: flex-end;
    align-items: center;
    flex: 0 0 auto;
  }
  &-icon {
    width: 18px;
    height: 18px;
    margin-left: 6px;
    flex: 0 0 auto;
    background-position: 50% 50%;
    background-size: auto 12px;
    background-repeat: no-repeat;
    opacity: 0.7;
    transition: opacity 70ms ease;
    cursor: pointer;
    &-edit {
      background-image: url("../../assets/images/icons/icon-edit.svg");
    }
    &-delete {
      background-image: url("../../assets/images/icons/icon-trash.svg");
    }
    &:hover {
      opacity: 1;
    }
  }

  &-list {
    /* rule-list-header */
    &-div {
      background: $tertiaryBackground;
      z-index: 25;
    }
    &-header {
      position: relative;
      display: flex;
      width: 100%;
      height: 48px;
      padding-right: 12px;
      padding-left: 12px;
      align-items: center;
      border-bottom: 1px solid $secondaryBackground;
      border-top: 1px solid $secondaryBackground;
      color: $secondaryFontColor;
      font-size: $fontSizeMediumLarge;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: 0.16px;
    }
    &-icon {
      width: 15px;
      height: 15px;
      margin-top: 2px;
      margin-right: 8px;
      background-image: url("../../assets/images/icons/icon-rule.svg");
      background-position: 50% 50%;
      background-size: 12px;
      background-repeat: no-repeat;
      opacity: 0.8;
    }
    &-close {
      position: absolute;
      right: 17px;
      display: flex;
      font-size: $fontSizeHeadingLarge;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      opacity: 0.7;
      transition: opacity 70ms ease;
      cursor: pointer;
      color: #fff;
      &:hover {
        opacity: 1;
      }
    }
  }
}
/* OUTPUTS */

.output {
  &-container {
    display: flex;
    padding-top: 20px;
    justify-content: center;
  }
  &-table {
    display: table;
    width: 90%;
  }

  &-row {
    display: table-row;
    &-header {
      background: #4d4d4d;
    }
  }
  &-cell {
    display: table-cell;
    padding: 5px;
  }
}

.close-editor-menu-icon {
  cursor: pointer;
}

.editor-previous-step-container {
  width: calc(100% - 8px);
  padding: 5px;
}

.editor-previous-step-label {
  margin: 0 auto;
  width: 100%;
  padding: 5px;
  padding-left: 0;
  margin-top: 1px;
  font-size: 11px;
}

div.wait-node-time-option {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  justify-content: space-evenly;
  margin: 20px 0;
  margin-left: 4px;
  font-size: 12px;
  & div {
    flex-basis: 100%;
    text-align: left;
    & input {
      width: 80%;
      text-align: inherit;
      padding: 0;
    }
  }
}

div.schedule-run {
  & .schedule-run-rate {
    display: flex;
    flex-direction: row;
    width: "70%";
    align-items: center;
    margin-bottom: 36px;
    margin-top: 28px;
    .number {
      width: 3rem;
      margin-top: 0;
    }
    & select {
      min-width: 110px;
      border-left: 0px;
      border-right: 0px;
      border-top: 0px;
      border-bottom: 1px solid #8c8c8c;
      height: 41px;
      color: #f3f3f3;
      padding-left: 10px;
      cursor: pointer;
      margin-left: 8px;
      -webkit-appearance: none;
      background: url("../../assets/images/icons/select-carrot.svg") no-repeat
        right;
      background-color: #3d3d3d;
      background-position-x: 88px;
    }
    & select:hover {
      background-color: #4c4c4c;
    }
  }
  & div.space-line {
    border-bottom: 1px solid $selectOptionBorderColor;
    line-height: 0;
    margin: 20px 0 5px 0;
    & span {
      background: $selectBackgroundColor;
      padding: 1px 10px;
      padding-left: 0px;
      font-size: 13px;
      text-transform: uppercase;
      opacity: 0.7;
    }
  }
  & div.cron-expression {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
    & > div {
      padding-right: 16px;
    }
    & input {
      margin-top: 8px;
      height: 40px;
      width: 40px;
    }
    & select {
      width: 128px;
      border-left: 0px;
      border-right: 0px;
      border-top: 0px;
      border-bottom: 1px solid #8c8c8c;
      height: 41px;
      color: #f3f3f3;
      padding-left: 10px;
      cursor: pointer;
      margin-top: 8px;
      -webkit-appearance: none;
      background: url("../../assets/images/icons/select-carrot.svg") no-repeat
        right;
      background-color: #3d3d3d;
      background-position-x: 106px;
    }
  }
  & span {
    font-size: 10px;
  }
  & .expression-text {
    padding-top: 20px;
    text-align: center;
    color: $checkmarkBackgroundHover;
    font-size: 16px;
    text-transform: capitalize;
    margin-bottom: -40px;
    &.heading {
      margin-right: 8px;
    }
    &.exp {
      color: #98c379;
    }
  }
  & .expression-input {
    font-size: 15px;
    width: 360px;
    word-spacing: 30px;
    margin-bottom: 16px;
  }
}
.m-2 {
  margin: 1rem;
}

.no-pointer {
  pointer-events: none;
  cursor: not-allowed;
}

.helper {
  padding: 0.3rem 1rem;
  max-width: 350px;
  .reactour__close {
    color: #f3f3f3;
    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
.mask {
  opacity: 0.5;
  background: rgba(23, 23, 23, 0.5);
}

.welcome-dialog {
  &-image-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1.1rem;
    background: #6b6b6b;
    padding: 2rem;
    img {
      max-width: 80%;
    }
  }
  &-footer {
    display: flex;
    justify-content: flex-end;
    margin-top: 2rem;
    &-button {
      height: 2rem;
      width: 5rem;
      color: #f3f3f3;
      font-size: 12px;
      border: none;
      cursor: pointer;
      background-color: #6300ff;
      opacity: 1;
      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.label-flex {
  display: flex;
  justify-content: space-between;
}
.label-link {
  display: inline-block;
  margin-bottom: 0.5rem;
  margin-right: 6px;
  flex: 0 0 auto;
  color: #bebebe;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  letter-spacing: 0.32px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.pt-0-px {
  padding-top: 0px;
}
.p-0-px {
  padding: 0px;
}
.json-output {
  max-height: 400px;
  min-height: 200px;
  overflow: scroll;
  background: #252525;
  padding-left: 12px;
}

//Editor Modal Styles

.modal-cover__editor-welcome {
  background: hsla(0, 0%, 95.3%, 0.35);
}

.modal-container__editor {
  box-shadow: 0 0.5em 3em rgba(0, 0, 0, 0.3);
  background-color: #3d3d3d;
  padding: 1rem;
  padding-top: 3.5rem;
  &-welcome {
    box-shadow: 0 0.5em 3em rgba(0, 0, 0, 0.3);
    max-width: 500px;
    background-color: #282828;
    padding: 0;
  }
  &-transactional {
    max-width: 500px;
    position: relative;
    padding: 1rem;
    padding-top: 3.5rem;
    background-color: #3d3d3d;
    box-shadow: 0 0.5em 3em rgba(0, 0, 0, 0.3);
  }
}

.modal-close__editor:hover {
  background-color: #6f6f6f;
}

.modal-content-container__editor {
  &-json {
    background-color: #252525;
    padding: 1rem;
  }
}

.modal-content__editor {
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 400;
  color: #f3f3f3;
  &-json {
    padding: 0;
    margin-bottom: 0;
    position: relative;
  }
  &-transactional {
    padding: 0;
    padding-top: 1rem;
  }
}

.editor-dropdown__versions {
  width: 100%;
  display: inline-flex;
  height: 40px;
  border: 1px solid #303030;
  cursor: pointer;
  padding-left: 12px;
  color: #f3f3f3;
  font-weight: 400;
  font-size: 13px;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("../../assets/images/icons/select-carrot.svg");
  background-repeat: no-repeat;
  background-position: 97%;
  background-color: #555;
  &:hover {
    background-color: #606060;
  }
}

.configue-output__wrap {
  margin: -16px;
  background-color: #3d3d3d;
}

.configure-modal__item-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.configure-modal__input {
  min-width: 60px;
  max-width: 60px;
}
.ht-100p {
  height: 100%;
}

.w-96 {
  width: 96%;
}

/* Tooltip customization */

.editor-menu-item-container > .__react_component_tooltip {
  padding: 4px 8px;
  font-size: 11px;
}

.editor-toolbar-json-content-button > .__react_component_tooltip {
  padding: 4px 8px;
  font-size: 11px;
}

.duplicate-tag-error {
  color: #ff5b5b;
  margin: 5px 0 0 2px;
  font-size: small;
}

/* END OF RULE CSS */
.editor {
  display: flex;
  min-height: 100vh;
  &-middle {
    display: flex;
    flex-direction: column;
    width: calc(100% - 524px);
  }
}

/* Editor  Settings CSS*/

.oval {
  &-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
  &-item {
    border-style: none;
    border-width: 1px;
    border-color: #282828;
    background-color: #535353;
    color: #f3f3f3;
    font-size: $fontSizeMediumSmall;
    height: 1.5rem;
    border-radius: 0.9375rem;
    text-align: center;
    padding-left: 8px;
    padding-right: 2px;
    margin-right: 5px;
    margin-top: 5px;
    cursor: pointer;
    &-inner-wrap {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  &-x {
    margin-left: 10px;
    border-radius: 8px;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.25rem;
    padding: 0.125rem;
    flex: 0 0 auto;
    background-image: url("../../assets/images/icons/icon-close.svg");
    background-position: 50% 50%;
    background-size: 8px;
    background-repeat: no-repeat;
    opacity: 0.5;
    transition: all 90ms ease;
    border-radius: 100%;
    &:hover {
      background-color: #353535;
    }
  }
}

.paragraph {
  color: hsla(0, 0%, 95.3%, 0.9);
  font-size: $fontSizeMedium;
  line-height: 18px;
  text-align: left;
  padding: 0 1rem 1rem;
  max-width: 24rem;
}
.paragraph-editor {
  color: hsla(0, 0%, 95.3%, 0.9);
  font-size: $fontSizeSmall;
  line-height: 18px;
  text-align: left;
  padding: 16px;
  margin-bottom: 14px;
}

// for output tab in editor right panel
.width-20-pct {
  width: 20%;
}
.width-80-pct {
  width: 80%;
}

// for editor left panel setting configuration

span.default-version {
  background-color: #656565;
  color: #f3f3f3;
  padding: 6px 8px;
  font-size: 10px;
  display: inline-block;
  border-radius: 2px;
  text-transform: uppercase;
  margin-left: 10px;
}

// for multiselect inside editor

.editor-multiselect {
  &.bx--list-box {
    background-color: #555;
    border: 0px solid transparent;
    &:hover {
      background-color: #616161;
    }
  }
  & > button > .bx--list-box__label {
    color: #f3f3f3;
    opacity: 0.5;
  }
  & > .bx--list-box__menu {
    background-color: #555;
    & > .bx--list-box__menu-item {
      &:hover {
        background-color: #616161;
      }
      &--active {
        background-color: #616161;
      }
      & > .bx--list-box__menu-item__option {
        border-bottom: 1px solid #616161;
        border-top: 1px solid transparent;
      }
    }
  }
  &.error {
    box-shadow: inset 0px 0px 0px 2px #f55;
  }
}

.input-tab {
  padding-bottom: 0;
}

.header-node-title {
  display: flex;
  align-items: baseline;
}

.ext-link-icon {
  width: 10px;
  height: 10px;
  margin-left: 7px;
  background-position: 50% 50%;
  background-size: 10px;
  background-repeat: no-repeat;
  opacity: 0.6;
}

.doc-tooltip {
  padding: 5px 8px !important;
}
