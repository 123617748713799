@import "../../scss/variables.scss";

.no-scroll {
  overflow: hidden;
}

.copy-text {
  font-size: 14px;
  padding-left: 16px;
  background: #3d3d3d;
  color: #78a9ff;
  &:hover {
    background: #3d3d3d;
  }
  &:focus {
    box-shadow: none;
  }
}

$error-color: red;

.overflow-items {
  display: contents;
  cursor: pointer;
}

.disabled {
  pointer-events: none;
}
.w-form-fail {
  color: $error-color;
}

.api-key-value {
  display: flex;
  width: 100%;
  input {
    width: 80%;
    font-size: 13px;
    font-weight: 400;
    background-color: transparent;
    border: 0;
    color: #bebebe;
  }
  img {
    margin-left: 1rem;
  }
}

.account-id {
  padding-top: 1rem;
  color: #5ca8ff;
  padding-left: 1rem;
}

.aws-settings-list {
  width: inherit;
}

.aws-settings-list > div > div {
  overflow-x: inherit;
  overflow-y: unset !important;
}

.target-account-info-container {
  margin-top: 32px;
  padding-left: 16px;
}

.default-tag {
  font-size: 10px;
  background-color: #656565;
  color: #f3f3f3;
  padding: 6px 9px;
  display: inline-block;
  border-radius: 2px;
  text-transform: uppercase;
  margin-left: 10px;
}

.default-select {
  &-control {
    height: 2.5rem;
    font-size: 14px;
    font-weight: 400;
    border: none;
    border-bottom: 1px solid #8c8c8c;
    background: #3d3d3d;
    min-width: 12rem;
    &:hover {
      background: #4c4c4c;
    }
  }
  &-list-item {
    height: 40px;
    font-size: 14px;
  }
  &-options {
    &-container {
      min-height: 2.5rem;
      background: #3d3d3d;
      width: 100%;
      border-radius: 0;
      border: 1px solid #f3f3f3;
    }
    &-list {
      height: 100%;
    }
  }
  &-value-container {
    padding-left: 10px;
  }
}
.cloudwatch-instruction-json-copy-icon {
  top: 0px;
  right: 0px;
}

.settings {
  .tabs__nav-item-dark-inactive,
  .tabs__nav-item-dark-active {
    width: 10rem;
  }
  .settings-multiselect {
    &.bx--list-box {
      background-color: #3d3d3d;
      height: 2.5rem;
      border-bottom: 1px solid #8c8c8c;
      &:hover {
        background-color: #4c4c4c;
      }
    }
    & > button > .bx--list-box__label {
      color: #f3f3f3;
      opacity: 0.5;
    }
    & > .bx--list-box__menu {
      background-color: #3d3d3d;
      & > .bx--list-box__menu-item {
        &:hover {
          background-color: #4c4c4c;
        }
        &--active {
          background-color: #3d3d3d;
        }
        & > .bx--list-box__menu-item__option {
          border-bottom: 1px solid #4c4c4c;
          border-top: 1px solid transparent;
        }
      }
    }
    &.error {
      box-shadow: inset 0px 0px 0px 2px #f55;
      border: 0 solid transparent;
    }
    &.disabled {
      &.bx--list-box {
        &:hover {
          background-color: none;
        }
      }
      & > button > .bx--list-box__label {
        opacity: 0.5;
      }
    }
  }
}

.configure-link {
  height: 40px;
  color: #7cb9ff;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 9rem;
  }
  &:hover {
    background-color: #3d3d3d;
  }
}
