.add-slack-variable {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 30px;
  height: 30px;
}

.add-slackvariable-button {
  display: flex;
  width: auto;
  justify-content: center;
  max-width: none !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 11px;
  margin-top: 30px;
  height: 28px;
  line-height: 18px;
  margin-bottom: 16px;
  border: 1px solid #303030;
  &:focus {
    border: 1px solid #303030;
    box-shadow: none;
  }
  &:hover {
    border: 1px solid #303030;
  }
  &:active {
    background-color: #606060 !important;
  }
}

.save-slackvariable-button {
  display: flex;
  width: auto;
  justify-content: center;
  max-width: none !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  font-size: 11px;
  margin-top: 30px;
  height: 28px;
  line-height: 18px;
  margin-bottom: 16px;
  border: 1px solid #3f00a2;
  &:focus {
    border: 1px solid #3f00a2;
    box-shadow: none;
  }
  &:hover {
    border: 1px solid #3f00a2;
  }
}

.insert-dynamic-variable-button {
  position: relative;
  width: 100%;
  padding: 0px;
  background: #555555;
  margin-top: -1px;
  border-left: 1px solid #171717;
  border-right: 1px solid #171717;
  border-bottom: 1px solid #171717;
  button {
    background-color: #6d6c6f;
    border-top: 1px solid #171717;
    border-right: 1px solid #171717;
    border-bottom: 1px solid transparent;
    border-left: 1px solid transparent;
    color: white;
    height: 28px;
    font-size: 11px;
    line-height: 19px;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;
    &:hover {
      background-color: #606060;
    }
  }
}

// for slack variable in editor right panel
.slack-dynamic-variable-list {
  position: absolute;
  width: 100%;
  z-index: 100;
  background-color: #232324;
  ul {
    padding: 0px;
    padding-left: 6px;
    color: #f3f3f3;
    font-size: 12px;
    margin: 0px;
    li {
      padding: 5px;
    }
  }
}

.ql-editing {
  left: 0 !important;
}

.no-list-style {
  list-style: none;
}
.slack-editor {
  &-builder {
    background: #555 !important;
  }
  pre {
    background-color: dimgray !important;
  }
}
