.dti {
  &-item {
    display: flex;
    flex-direction: column;

    .ttDimension {
      padding: 6px;
      margin-left: -8px;
    }
  }
  &-label {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
      "Helvetica Neue", sans-serif;
    color: #bebebe;
    font-size: 12px;
  }
  &-subtext {
    font-size: 11px;
    margin-bottom: 0.5rem;
  }
  &-input {
    height: 40px;
    background: #555;
    width: 100%;
    box-sizing: border-box;
    min-width: 352px;
    border: 0px solid #303030;
    color: #f3f3f3;
    line-height: 20px;
    font-size: 14px;
    padding-left: 16px;
    cursor: default;
    &:focus {
      border-color: transparent;
      background-color: #616161;
      box-shadow: inset 0 0 0 2px #f3f3f3;
      border: none;
    }

    &:hover {
      background-color: #616161;
    }
    &-error {
      border: 2px solid #f55;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
      &:hover {
        background-color: #555;
      }
    }

    &::-webkit-input-placeholder {
      color: #bebebe;
      font-weight: 400;
    }
    &::placeholder {
      color: #f3f3f3;
      opacity: 0.5;
      font-weight: 400;
    }
  }

  &-icon {
    &-container {
      height: 2.5rem;
      min-width: 2.7rem;
      max-width: 2.7rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #555;
      border: 0px;
      border-left: 1px solid #303030;
      border-bottom: 0px solid #303030;
      cursor: pointer;
      &:hover {
        background: #525252;
      }
      &:focus {
        box-shadow: inset 0px 0px 0px 2px #f3f3f3;
      }
    }

    &-copy {
      cursor: pointer;
      opacity: 1;
      height: 16px;
    }
    &-mask {
      cursor: pointer;
      fill: #8c8c8c;
      height: 18px;
    }
  }
}
