@import "@scss/variables.scss";

.invalid-json {
  visibility: hidden;
  color: $warnBackground;
}
.inline-accordion {
  height: 42px;
  align-items: center;
  background: #555;
  border: none;
  box-shadow: 0 0 0 1px transparent;
  transition: all 70ms ease;
  font-family: Ibmplexmono, sans-serif;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 1px;
  &-left {
    width: -webkit-fill-available;
    padding-left: 6px;
  }
  &-right {
    padding-left: 14px;
    margin-left: 2px;
    width: 42px;
    &:hover > img {
      opacity: 1;
    }
    &:active {
      background: #393939;
    }
  }
  &:hover {
    background: #616161;
  }
  &:focus {
    box-shadow: inset 0px 0px 0px 2px #f3f3f3;
  }
  .label {
    color: #f3f3f3;
  }
}
.helper-content {
  position: absolute;
  padding: 4px 10px;
  border-radius: 16px;
  width: auto;
  background: #f3f3f3;
  pointer-events: none;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  color: #282828;
  text-transform: uppercase;
  top: 45px;
}
.submit-disabled {
  background-color: #6300ff;
  border: 2px solid #6300ff;
  opacity: 0.5;
  cursor: not-allowed;
  &:hover {
    background-color: #6300ff;
  }
  &:focus {
    box-shadow: none;
    background-color: #6300ff;
  }
}

.hide-error {
  visibility: visible;
}

.editor-container {
  margin-bottom: -3rem;
}

.JSON-viewer {
  &-container {
    padding-top: 12px;
    background-color: #555;
    border: 2px solid transparent;
    box-shadow: 0 0 0 1px transparent;
    transition: all 70ms ease;
    display: flex;
    justify-content: center;
    height: 120px;
    position: relative;
    margin-top: 2px;
    &:focus-within {
      border-color: transparent;
      box-shadow: inset 0 0 0 2px #f3f3f3;
      border: 2px solid transparent;
    }
    &:hover {
      background-color: #616161;
    }
  }
  &-content {
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(180deg, #f3f3f3 68%, #555 98%);
    border: none;
    background-clip: text;
    overflow: hidden;
    border: none;
    height: 100px;
    font-family: Ibmplexmono, sans-serif;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.16px;
    margin-bottom: 0px;
    border: 0px solid #2e2e2e;
    background-color: #555;
    transition: all 70ms ease;
    color: #f3f3f3;
    line-height: 18px;
    width: 100%;
    padding: 8px 16px;
  }
}
.jv-icon {
  padding: 4px;
  &-delete {
    margin-left: auto;
    opacity: 0.7;
    margin-right: 14px;
    &:hover {
      opacity: 1;
    }
  }
  &-collapsed {
    transform: rotate(-90deg);
    transition: transform 0.1s linear;
  }
  &-expanded {
    transform: rotate(0deg);
    transition: transform 0.1s linear;
  }
}

.JSON-viewer-container + .editor-gear-icon-wrap {
  top: 20px;
}
.mb-10-px + .editor-gear-icon-wrap {
  top: 20px;
}

.label-wrapper {
  display: flex;
  flex-direction: column;
  label {
    font-size: 12px;
  }
}
