.hb {
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  .breadcrumbs {
    display: flex;
    align-items: center;
    .link {
      transition: all 90ms ease;
      color: #bebebe;
      font-size: 13px;
      line-height: 1.25rem;
      font-weight: 300;
      letter-spacing: 0.16px;
      text-decoration: none;
      cursor: pointer;
      opacity: 0.9;
      &:hover {
        opacity: 1;
        text-decoration: underline;
      }
      &-disabled {
        &:hover {
          opacity: 0.9;
          text-decoration: none;
          cursor: default;
        }
      }
    }
    .divider {
      width: 1px;
      height: 0.875rem;
      margin: 0 0.5rem;
      background-color: #f3f3f3;
      opacity: 0.7;
      transform: skew(-20deg, 0deg);
    }
  }
  .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    .text {
      display: flex;
      font-size: 1.875rem;
      line-height: 2.675rem;
      font-weight: 400;
      letter-spacing: 0.32px;
      .title {
        color: #fff;
      }
      .id {
        margin-right: 12px;
        color: hsla(0, 0%, 100%, 0.62);
      }
    }
    .actions {
      display: flex;
      position: relative;
      button {
        display: flex;
        align-items: center;
        box-sizing: border-box;
        height: 100%;
        min-height: 0rem;
        flex: 0 0 auto;
        border: none;
        transition: all 100ms ease;
        color: #fff;
        font-weight: 400;
        width: 50%;
        padding-left: 16px;
        background: #6300ff;
        width: 120px;
        cursor: pointer;
        &:hover {
          background-color: #6208f1;
        }
        &:focus {
          box-shadow: inset 0px 0px 0px 2px #f3f3f3;
          background: #5203cf;
        }
      }
      .dropdown-btn {
        width: 40px;
        margin-left: 2px;
        img {
          transform: rotate(90deg);
        }
      }
      .dropdown {
        display: flex;
        flex-direction: column;
        background-color: #3d3d3d;
        position: absolute;
        top: 42px;
        width: 162px;
        z-index: 1;
        .option {
          cursor: pointer;
          padding: 12px 16px;
          height: 2.5rem;
          &.delete {
            &:hover {
              background-color: #f53b43;
              color: #f3f3f3;
            }
          }
          &:hover {
            background-color: #4c4c4c;
            color: #f3f3f3;
          }
        }
      }
    }
  }
}
