@import "../../scss/variables.scss";
.next-schedule-list {
  padding: 5px;
  & .title {
    font-size: 15px;
    padding-bottom: 10px;
    font-weight: 500;
  }
  & > div {
    font-size: 12px;
    padding: 6px 0;
  }
  & > div:not(:last-child) {
    border-bottom: 1px dotted gray;
  }
}
.schedules-clear-filters {
  padding: 35px 15px;

  & a {
    text-decoration: underline;
    color: #bebebe;
    display: inline-table;
    margin-left: 15px;
  }
}
