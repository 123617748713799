.menu-list {
  width: 170px;
  height: auto;
  position: absolute;
  left: 12px;
  top: 12px;
  right: auto;
  bottom: auto;
  background-color: #3d3d3d;
  box-shadow: 0 0 12px 3px rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.list-item {
  display: flex;
  width: 100%;
  height: 40px;
  padding-right: 16px;
  padding-left: 16px;
  align-items: center;

  &:hover {
    background-color: #555;
  }
}

.list-icon {
  width: 22px;
  height: 18px;
  margin-right: 10px;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;

  &-home {
    background-image: url("../../../../../../assets/images/icons/icon-home.svg");
  }

  &-workflow {
    background-image: url("../../../../../../assets/images/icons/icon-runbooks.svg");
  }
}

.list-text {
  color: #f3f3f3;
  display: flex;
  width: 100%;
  height: auto;
  margin-left: 0px;
  padding-right: 0px;
  padding-left: 0px;
  align-items: center;
}

.list-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  z-index: 90;
  cursor: auto;
}
