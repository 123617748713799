.wf-btn-container > .delete:hover {
  background-color: #fb4b53;
  border: 2px solid #fb4b53;
  filter: brightness(1);
}

.wf-btn-container > .__react_component_tooltip {
  padding: 4px 8px;
  font-size: 13px;
}

.wf-btn {
  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 16rem;
    height: 2.5rem;
    &-fylamynt {
      width: 13rem;
    }
  }
  &-item {
    background-color: #353535;
    border-width: 0px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    &:hover {
      background-color: #525252;
    }
    &:focus {
      box-shadow: inset 0px 0px 0px 2px #f3f3f3;
    }
  }
}
