.sl {
  &-header {
    font-size: 12px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
  }
  &-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    max-height: 10rem;
  }
}
