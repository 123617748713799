.input {
  &-wrap {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;

    .ttDimension {
      padding: 6px;
      margin-left: -8px;
    }

    &-input-container {
      width: -webkit-fill-available;
      text-overflow: ellipsis;
      background-color: #555;
      color: #f3f3f3;
      height: 40px;
      font-size: 14px;
      padding-left: 16px;
      margin-top: 0.5rem;
      border: 0px solid #000;
      &:hover {
        filter: brightness(1.2);
      }
      &:focus {
        border-color: transparent;
        background-color: #616161;
        box-shadow: inset 0 0 0 2px #f3f3f3;
        border: none;
      }
    }
    &-icon {
      min-width: 42px;
      max-width: 42px;
      height: 40px;
      padding: 14px 0 0 14px;
      display: flex;
      margin-top: 8px;
      background: #555;
      border: none;
      border-left: 0px solid #000;
      margin-left: 2px;
      outline: none;
      cursor: pointer;
      &:hover {
        filter: brightness(1.2);
      }
      &:focus {
        box-shadow: inset 0 0 0 2px #f3f3f3;
      }
      &:active {
        background: #393939;
      }
      &__eye {
        padding: 6px 0 0px 18px;
      }
    }
  }
}

.eye-icon {
  z-index: 1;
  cursor: pointer;
  margin-left: -0.3rem;
  fill: #bebebe;
  height: 30px;
}
