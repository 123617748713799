.tw {
  margin-bottom: 10px;
  padding: 24px 32px 32px;
  background-color: #282828;
  .header {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
      flex-direction: column;
      .title {
        font-size: 1.5rem;
        line-height: 2.2rem;
        font-weight: 300;
        color: #f3f3f3;
      }
      .description {
        font-size: 14px;
        line-height: 20px;
        font-weight: 300;
        color: hsla(0, 0%, 95.3%, 0.8);
      }
      .info {
        line-height: 50px;
        color: #f3f3f3;
        font-size: 16px;
        font-weight: 300;
      }
    }
    .right {
      display: flex;
      align-items: center;
      .toggle {
        display: flex;
        justify-content: space-between;
        width: 92px;
        .status {
          color: #bebebe;
          font-size: 14px;
          margin-top: 4px;
        }
      }
    }
  }
}
