/* TITLES, HEADERS */
.title-wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 1rem;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
}

.page-title {
  margin-bottom: 0px;
  color: #fff;
  font-size: 1.875rem !important;
  line-height: 2.675rem;
  letter-spacing: 0.32px;

  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: 400;
}

.centered {
  justify-content: center;
  text-align: center;
  justify-items: center;
}

a {
  background-color: transparent;
}

.link-white {
  color: #f3f3f3;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.no-text-decoration {
  &:hover {
    text-decoration: none;
  }
}

.link-light-blue {
  color: #5ca8ff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.header-light-blue {
  color: #5ca8ff;
}

/* FORM ELEMENTS */
.label {
  display: inline-block;
  margin-bottom: 0.5rem;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  color: #bebebe;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  letter-spacing: 0.32px;
  cursor: default;
}

.switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1rem;
  width: 1rem;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3dbb61;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3dbb61;
}

input:checked + .slider:before {
  -webkit-transform: translateX(1rem);
  -ms-transform: translateX(1rem);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 2rem;
}
.slider.round:before {
  border-radius: 50%;
}
.enable-notifications {
  &-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
/* DIVS */

/*Editor Settings Specific CSS to be moved to RunbookEditor.scss later*/

.p-10-px {
  padding: 10px;
}

.p-20-px {
  padding: 10px;
}

/*Editor Settings Specific CSS END*/

/* GRIDS */
.grid-3-columns {
  display: grid;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  grid-template-columns: 1fr 3fr 1fr;
  column-gap: 2px;

  & > div {
    height: 100%;
  }
}

/* FORMS, BUTTONS, INPUTS */
.button {
  &-delete {
    background: #fb4b53;
    color: #f3f3f3;
    &:hover {
      background: #f8353e;
    }
  }
  &-grey {
    background: rgb(61, 61, 61);
    &:hover {
      background: #444;
    }
  }
  &-error {
    border: 1px solid #ff5b5b !important;
  }
}

/* miscellaneous */
.found-text {
  color: yellow;
}

.text-area {
  height: 200px;
  min-height: 200px;
  margin-bottom: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #2e2e2e;
  background-color: #555;
  -webkit-transition: all 70ms ease;
  transition: all 70ms ease;
  color: #f3f3f3;
  font-size: $fontSizeMedium;
  line-height: 18px;
  width: 100%;
}
.compact-text-area {
  min-height: 132px;
  margin-bottom: 0px;
  border: 0px solid #2e2e2e;
  background-color: #555;
  -webkit-transition: all 70ms ease;
  transition: all 70ms ease;
  color: #f3f3f3;
  font-size: $fontSizeLarge;
  line-height: 18px;
  width: 100%;
  padding: 12px 16px;
  &:focus {
    border-color: transparent;
    box-shadow: inset 0 0 0 2px #f3f3f3;
    border: none;
  }
  &:hover {
    background-color: #616161;
  }
}

/* Common footer class for all the forms */
.form-footer {
  width: 100%;
  height: 3.5rem;
  display: flex;
  margin-top: 1rem;
}

.text-small {
  font-size: $fontSizeMediumSmall;
}

*.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;

  /*
     Introduced in IE 10.
     See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
   */
  -ms-user-select: none;
  user-select: none;
}

.no-pointer {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
}

.scroll-x-auto {
  overflow-x: auto;
}

.scroll-y-auto {
  overflow-y: auto;
}

.pointer {
  cursor: pointer;
}

.default-cursor {
  cursor: default;
}

.link-underline {
  text-decoration: underline;
}

.no-error {
  border-bottom: 1px solid #8c8c8c;
}

/* FLEX CLASSES */
.d-flex {
  display: flex;
}

.d-inline-flex {
  display: inline-flex;
}

.flex-column {
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-center {
  align-items: center;
}

/* BOOTSTRAP CLASSES */

.d-inline-block {
  max-width: 100%;
  display: inline-block;
}

.d-block {
  max-width: 100%;
  display: block;
}

.w-25 {
  width: 25%;
}

.w-50 {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.w-100 {
  width: 100%;
}

.w-auto {
  width: auto;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 0.5rem;
}

.pl-1 {
  padding-left: 0.5rem;
}

.pl-2 {
  padding-left: 1rem;
}

.mt-0 {
  margin-top: 0;
}

.mt-n1 {
  margin-top: -0.5rem;
}

.mt-2 {
  margin-top: 1rem;
}

.pt-2 {
  padding-top: 15px;
}
.mb-2 {
  margin-bottom: 1rem;
}

.pb-2 {
  padding-bottom: 1rem;
}

.ml-2 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: 1rem;
}

.pr-2 {
  padding-right: 1rem;
}
.mb-4 {
  margin-bottom: 2rem;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.position-static {
  position: static;
}
/* MARGINS, PADDING, FIXED WIDTHS */
.mt-2-px {
  margin-top: 2px;
}

.mb-2-px {
  margin-bottom: 2px;
}

.mt-5-px {
  margin-top: 5px;
}

.pt-5-px {
  padding-top: 5px;
}

.mb-5-px {
  margin-bottom: 5px;
}

.ml-5-px {
  margin-left: 5px;
}

.mr-5-px {
  margin-right: 5px;
}

.mt-10-px {
  margin-top: 10px;
}

.pt-10-px {
  padding-top: 10px;
}

.mb-10-px {
  margin-bottom: 10px;
}

.pb-10-px {
  padding-bottom: 10px;
}

.ml-10-px {
  margin-left: 10px;
}

.mr-10-px {
  margin-right: 10px;
}

.mr-12-px {
  margin-right: 12px;
}
.mt-20-px {
  margin-top: 20px;
}

.pt-20-px {
  padding-top: 20px;
}

.mb-20-px {
  margin-bottom: 20px;
}

.pb-20-px {
  padding-bottom: 20px;
}

.pt-24-px {
  padding-top: 24px;
}
.pb-30-px {
  padding-bottom: 30px;
}

.pr-30-px {
  padding-right: 30px;
}

.mb-35-px {
  margin-bottom: 35px;
}

.mt-40-px {
  margin-top: 40px;
}

.mt-50-px {
  margin-top: 50px;
}

.mb-50-px {
  margin-bottom: 50px;
}

.pr-50-px {
  padding-right: 50px;
}

.mt-100-px {
  margin-top: 100px;
}
