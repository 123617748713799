.warning-card {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border-left: 3px solid #fed23a;
  background-color: #3d3d3d;
  color: #f3f3f3;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.16px;
  max-width: 25rem;
  z-index: 4;
  position: relative;

  &-header {
    display: flex;
    justify-content: space-between;
    width: auto;
    align-items: center;
    &-text {
      font-family: IBMplexsans-SemiBold, sans-serif;
      font-size: 15px;
    }
  }
  &-link {
    cursor: pointer;
    color: #5ca8ff;
    font-size: 13px;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.16px;
    text-decoration: none;
    &:hover {
      color: #7cb9ff;
      text-decoration: none;
    }
  }
  &-text {
    margin: 1rem 0rem;
    font-size: 13px;
    line-height: 17px;
  }
  &-ul {
    font-family: IBMplexsans-Medium, sans-serif;
  }
}
