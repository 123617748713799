.snippets {
  &-clear-filters {
    padding: 35px 15px;

    & a {
      text-decoration: underline;
      color: #bebebe;
      display: inline-table;
      margin-left: 15px;
    }
  }

  &-filter-input {
    width: 80%;
  }

  &-service-filter {
    min-width: 16rem;
  }

  &-tags-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0.5rem 0;
    & > span {
      margin: 0.25rem;
    }
  }
}
