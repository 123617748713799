.webhook {
  &-copy-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-input {
    display: flex;
    flex-direction: column;
    padding: 12px;
    justify-content: space-between;
    &-label {
      color: hsla(0, 0%, 95.3%, 0.7);
      font-size: 11px;
      font-weight: 400;
    }
    &-container {
      text-overflow: ellipsis;
      background-color: #363636;
      color: #f3f3f3;
      cursor: pointer;
      height: 1.8rem;
      font-size: 0.75rem;
      padding-left: 10px;
      padding-right: 40px;
      margin-top: 0.5rem;
      border: 1px solid #2b2b2b;
      &:hover {
        background-color: #333333;
      }
    }
    .copy-wrap .ttDimension {
      padding: 6px;
      margin-left: -8px;
    }
  }
}
.copy-wrap {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
}
.copy-icon-container {
  width: 27px;
  height: 27px;
  padding: 6px 0 0 6px;
  right: 1px;
  bottom: 1px;
  position: absolute;
  background: #424242;
  border-left: 1px solid #2b2b2b;
  &:hover {
    filter: brightness(1.2);
  }
}

.tooltip-container {
  width: 4rem;
  height: 1.5rem;
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
  padding: 0 !important;
  color: #171717 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
}

.input-eye-icon {
  cursor: pointer;
  fill: #f3f3f3;
}
.input-eye-icon-wrap {
  position: absolute;
  right: 26px;
  top: 3px;
}

.mask {
  &-input {
    padding-right: 4rem;
  }
  &-container {
    display: flex;
    align-items: center;
  }
}

.settings-link {
  color: #fd9b3f;
  font-size: 13px;
  line-height: 1.25rem;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.small-font-size {
  font-size: x-small;
}

.instructions {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  font-size: 12px;
}

// START WEBHOOK SLACK SELECT COMPONENT

.webhook-input > div > .select-container {
  margin-top: 8px;
}

.webhook-input
  > div
  > .select-container
  > .select-menu
  > .select-title-default {
  background: #555;
}

.webhook-input
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar {
  height: 32px !important;
  font-size: 12px;
  border: 1px solid #303030;
  &:hover {
    background: #555;
  }
}

.webhook-input
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > .pl-2 {
  padding-left: 4px;
}

.webhook-input
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > .flex-column-custom
  > input {
  width: 100%;
  font-size: 12px;
  padding-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.webhook-input
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > .flex-column-custom
  > .select-clear {
  margin-right: 4px;
  height: 14px;
  width: 17px;
  background: #6f6f6f;
  &:hover {
    background: #8c8c8c;
  }
}

.webhook-input
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .title-bar
  > .flex-column-custom
  > .select-input {
  margin-left: 0px;
}

.webhook-input
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column-custom
  > ul {
  background: #555;
  border-left: 1px solid #303030;
  border-right: 1px solid #303030;
}

.webhook-input
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column-custom
  > ul
  > li {
  background: #555;
  height: 32px !important;
  font-size: 12px;
  padding-left: 10px !important;
  &:hover {
    background: #6f6f6f;
  }
}

.webhook-input
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column-custom
  > ul
  > li
  > span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.webhook-input
  > div
  > .select-container
  > .select-menu
  > .select-title-default
  > .flex-column-custom
  > ul
  > li
  > a {
  color: #f3f3f3;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  &:focus {
    outline: none;
  }
}
