.text-area-approval {
  width: 94%;
  padding: 10px;
}

.editor-detail-panel .apr {
  &-time-input {
    width: 75%;
    min-width: unset;
    margin-right: 2px;
  }
  &-select-unit {
    width: 50%;
  }
  &-timeout-error {
    margin-top: 7px;
    color: #f55;
  }
}
