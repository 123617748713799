@import "../../../../../scss/variables.scss";

.editor {
  &-slideout {
    position: absolute;
    z-index: 5;
    top: 44px;
    left: 44px;
    overflow-x: hidden;
    overflow-y: hidden;
    height: calc(100% - 44px);
    background: $tertiaryBackground;
    min-width: 300px;
    padding-left: 8px;
    border-top: 1px solid $primaryBackground;
    &-content {
      border-left: 1px solid $secondaryBackground;
      height: 100%;
      &-heading {
        position: relative;
        display: inline-flex;
        width: 100%;
        height: 48px;
        padding-right: 12px;
        padding-left: 16px;
        align-items: center;
        border-bottom: 1px solid $secondaryBackground;
        font-family: IBMPlexSans-Regular, sans-serif;
        color: $secondaryFontColor;
        font-size: $fontSizeHeading;
        line-height: 27px;
        font-weight: 400;
        letter-spacing: 0.16px;
        &__title {
          width: 94%;
        }
      }
      &-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        &-search {
          display: flex;
          align-items: center;
          padding: 0px;
          position: relative;
          z-index: 5;
          border-bottom: 1px solid #2c2c2c;
          box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.15);
          &__input {
            height: 40px;
            border: none;
            border-color: $canvasPrimaryBackground;
            background-color: $ruleInputBackground;
            font-size: $fontSizeLarge;
            width: 100%;
            font-weight: 400;
            color: $secondaryFontColor;
            padding-left: 1rem;
            padding-right: 2rem;
            padding-top: 0px;
            &:hover {
              background-color: #616161;
            }
            &:focus {
              box-shadow: inset 0px 0px 0px 2px #f3f3f3;
            }
            &::placeholder {
              color: #bebebe;
            }
          }
          &__clear {
            height: 10px;
            width: 10px;
            margin-top: 3px;
          }
        }
        &-area {
          overflow-y: overlay;
          overflow-x: hidden;
          height: calc(100% - 48px);
          padding-bottom: 32px;
          &-actions {
            overflow-y: overlay;
            overflow-x: hidden;
            height: calc(100% - 90px);
            padding-bottom: 32px;
          }
          &-version-dropdown {
            &__open {
              position: absolute;
              top: 24px;
              width: 114px;
              margin-left: -88px;
              border-style: solid;
              border-width: 1px;
              border-color: #f3f3f3;
              background-color: #555;
              box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.19);
              cursor: default;
              z-index: 20;
            }
            &__closed {
              display: none;
            }
          }
        }
      }
    }
  }
}

.action-button {
  margin-left: auto;
  height: 28px;
  width: 28px;
  border: 1px solid $canvasPrimaryBackground;
  background-color: #555;
  text-align: center;
  position: absolute;
  z-index: 5;
  top: 10px;
  right: 10px;
  cursor: pointer;
  &:hover {
    background-color: #616161;
  }
  &:focus {
    box-shadow: inset 0px 0px 0px 2px #f3f3f3;
  }
}
.action-button > img {
  height: 12px;
  width: 12px;
}

.accordion-label-container.actions-accordion {
  height: 44px;
  background-color: #3d3d3d;
  padding-left: 16px;
  text-transform: capitalize;
  border-bottom: 1px solid #292929;
  border-top: 1px solid #3d3d3d;
  font-size: 14px;
  font-weight: 400;
}

.actions-accordion > .accordion-label {
  font-size: 14px;
  font-family: IBMPlexSans-Medium;
  color: #f3f3f3;
  text-transform: none;
  & > .accordion-arrow-right {
    right: 13px;
  }
}

.search-clear {
  height: 24px;
  width: 24px;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 10px;
  cursor: pointer;
  &:hover {
    background-color: #6f6f6f;
  }
  &:focus {
    box-shadow: 0px 0px 0px 2px #f3f3f3;
  }
}

.panel-close {
  height: 24px;
  width: 24px;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 10px;
  cursor: pointer;
  &:hover {
    background-color: #616161;
  }
  &:focus {
    box-shadow: 0px 0px 0px 2px #f3f3f3;
  }
}
