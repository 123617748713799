@import "../../../../scss/variables.scss";

.editor {
  &-middle {
    &-toolbar {
      display: flex;
      height: 44px;
      justify-content: space-between;
      background-color: $secondaryAccentColor;
      &-left {
        display: flex;
        width: auto;
        height: 44px;
        background-color: #282828;
        border: 0px;
        padding-right: 16px;
        padding-left: 16px;
        align-items: center;
        border-right: 1px solid $canvasBorder1;
        transition: all 70ms ease;
        color: $secondaryFontColor;
        font-size: $fontSizeLarge;
        font-weight: 300;
        letter-spacing: 0.16px;
        text-decoration: none;
        cursor: pointer;
        &:hover {
          background-color: $tertiaryBackground;
        }
        &:focus {
          box-shadow: inset 0px 0px 0px 2px #f3f3f3;
        }
        & .link-white {
          padding-right: 4px;
        }
        .default-version-tags {
          background-color: #656565;
          color: #f3f3f3;
          padding-right: 6px;
          padding-left: 6px;
          font-size: 8px;
          line-height: 18px;
          font-weight: 400;
          letter-spacing: 0.32px;
          display: inline-block;
          border-radius: 2px;
          text-transform: uppercase;
          margin-left: 10px;
          height: 18px;
        }
        .version-number {
          opacity: 0.7;
          margin-left: 5px;
        }
        .wf-title {
          white-space: nowrap;
        }
      }
      &-right {
        display: flex;
        flex-direction: column;
        &-content {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          position: relative;
          &-json {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3rem;
            border: 0px;
            background-color: #282828;
            border-right: 1px solid #1d1d1d;
            border-left: 1px solid #1d1d1d;
            height: 44px;
            background-size: contain;
            z-index: 10;
            cursor: pointer;
            &:hover {
              background-color: #3d3d3d;
            }
            &:focus {
              box-shadow: inset 0px 0px 0px 2px #f3f3f3;
            }
          }
          & .workflow-run-dropdown {
            background: $btnSecondaryBackground;
            height: 44px;
            border: 0px;
            &:focus {
              box-shadow: inset 0px 0px 0px 2px #f3f3f3;
            }
          }
          & .dropdown-list {
            overflow-y: hidden;
          }
        }
      }
    }
  }
}

.btn-svg {
  position: absolute;
  top: calc(50% - 8px);
  right: 0.5rem;
  width: 1rem;
  height: 1rem;
  flex: 0 0 auto;
  background-size: 12px;
  background-repeat: no-repeat;
  &__run {
    background-image: url("../../../../assets/images/icons/icon-play.svg");
  }
  &__schedule {
    background-image: url("../../../../assets/images/icons/icon-hourglass.svg");
    filter: invert(100%);
  }
  &__save {
    top: calc(50% - 6px);
    background-image: url("../../../../assets/images/icons/save-icon-white.svg");
  }
}

.button-run {
  padding-left: 15px;
  width: 130px;
  font-size: $fontSizeMediumLarge;
  background: $secondaryButtonBackground;
  &:hover {
    background: $secondaryButtonBackgroundHover;
  }
}

.button-save {
  padding-left: 15px;
  width: 185px;
  font-size: $fontSizeLarge;
  &:hover {
    background: $primayButtonHoverBackground;
    border-color: $primayButtonHoverBackground;
  }
  position: relative;
}

.modal-title__json {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding-left: 1rem;
  padding-top: 0;
  height: 41px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #282828;
  width: 100%;
  background: #555;
  font-size: 15px;
}
