.create-task {
  width: 70%;
  &-description {
    height: 100px;
    min-height: 100px;
  }
  &-select {
    margin-bottom: 20px;
  }
  .wf-actions {
    span {
      font-size: 13px;
      color: #bebebe;
      margin-bottom: 5px;
    }
    .radio-group {
      margin-top: 10px;
      display: flex;
      .radio-button {
        margin-right: 30px;
        display: flex;
        .label {
          font-size: 0.875rem;
          line-height: 1.125rem;
          letter-spacing: 0.16px;
          color: #f3f3f3;
        }
        .icon {
          cursor: pointer;
          width: 1.125rem;
          height: 1.125rem;
          margin-top: 1px;
          margin-right: 8px;
          border: 1px solid #f3f3f3;
          border-radius: 50% 50%;
          &.checked {
            background-color: #f3f3f3;
            box-shadow: inset 0px 0px 0px 4px #282828;
          }
        }
      }
    }
  }
}
