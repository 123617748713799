.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  &-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    min-width: 72px;
    height: 40px;
    color: #f3f3f3;
    border: none;
    cursor: pointer;
    opacity: 1;
    font-size: 14px;
    line-height: 12px;
    transition: all 100ms ease;
    &:focus {
      box-shadow: inset 0 0 0 2px #f3f3f3;
    }
    &-cancel {
      background-color: #6f6f6f;
      margin-right: 1rem;
      &__editor {
        margin-right: 0.5rem;
        height: 2rem;
        width: auto;
        min-width: 4rem;
      }
      &:hover {
        background-color: #606060;
      }
      &:active {
        background-color: #393939;
      }
    }
    &-save {
      background-color: #6300ff;
      &__editor {
        height: 2rem;
        width: auto;
        min-width: 4rem;
      }
      &:hover {
        background-color: #6208f1;
      }
      &:active {
        background-color: #5203cf;
      }
    }
    &__danger {
      background-color: #fb4b53;
      &__editor {
        height: 2rem;
        width: auto;
        min-width: 4rem;
      }
      &:hover {
        filter: brightness(1.1);
      }
    }
  }
}
