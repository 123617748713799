.right-panel-container {
  height: 100vh;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  &-button {
    padding: 10px;
    width: 10em;
    background-color: #6300ff;
    font-size: 12px;
    font-weight: 400;
    margin-top: 8px;
    border: none;
    color: #fff;
    cursor: pointer;
    &:hover {
      background-color: #5e09e4;
    }
    a {
      color: #fff;
    }
  }
}
