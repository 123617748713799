.date-range {
  width: 25%;
  &-cc {
    background-color: #282828;
    border-bottom: 1px solid #8c8c8c;
    &:hover {
      background-color: #353535;
    }
  }
  &-menu {
    background-color: #282828;
    &-option {
      &:hover {
        background-color: #353535;
      }
    }
  }
}

.tl {
  &-container {
    display: flex;
    flex-direction: column;
    .actions {
      margin-top: 40px;
      display: flex;
      .search {
        width: 75%;
        margin-left: 2px;
        position: relative;
        input {
          background-color: #282828;
          border-bottom: 1px solid #8c8c8c;
          &:hover {
            background-color: #353535;
          }
        }
        .clear-search {
          position: absolute;
          height: 35px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          right: 2px;
          top: 2px;
          cursor: pointer;
          color: #f3f3f3;
          &:hover {
            background-color: #353535;
            color: white;
          }
        }
      }
    }
    .summary-cards {
      margin-top: 40px;
      display: grid;
      grid-column-gap: 32px;
      grid-template-columns: 1fr 1fr 1fr;
      .item {
        width: 100%;
        height: 100px;
        padding: 16px;
        background-color: #282828;
        color: hsla(0, 0%, 95.3%, 0.8);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .title {
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
        }
        .numbers {
          display: flex;
          align-items: flex-end;
          .dividend {
            color: #f3f3f3;
            font-size: 38px;
            line-height: 31px;
          }
          .divisor {
            margin-left: 4px;
            font-size: 18px;
          }
        }
      }
    }
    .data-table {
      margin-top: 40px;
      display: flex;
      .rdt_TableCell {
        padding: 0.875rem 0.5rem 0.8125rem 0.9rem;
      }
      .rdt_TableCol_Sortable {
        width: 100%;
        display: flex;
        justify-content: space-between;
        span {
          margin-right: 5px;
        }
        &:hover {
          background-color: #555;
        }
      }
    }
  }
}
