@import "../../../../scss/variables.scss";

.wf-card__logo {
  width: 28px;
  height: 28px;
  background-color: #f3f3f3;
  background-size: 18px;
  box-shadow: 0px 0px 0px 2px #282828;
  flex: 0 0 auto;
  border-width: 2px;
  border-style: none;
  position: relative;
  z-index: 5;
  border-color: #282828;
  border-radius: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.services-logos {
  display: flex;
}

.arrow {
  position: relative;
  left: -5px;
  z-index: 9;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 30px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}
