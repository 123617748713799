.runbooks-grid {
  padding-top: 30px;
  display: grid;
  margin-bottom: 32px;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  align-content: start;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  cursor: default;
}
