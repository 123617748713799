@import "../../scss/variables.scss";

.runbook-tabs > .tabs-wrap {
  border-bottom: 2px solid #252525;
}

.runbook-item__author {
  color: #bebebe;
  font-size: $fontSizeMediumLarge;
  font-weight: 400;
}

.welcome-dialog {
  display: flex;
  flex-direction: column;
  &__img-wrap {
    padding: 2rem;
    background: #6b6b6b;
    display: flex;
    justify-content: center;
  }
  img {
    width: 100%;
  }
  span {
    margin-top: 1rem;
  }
}

.welcome-content {
  margin-bottom: 1rem;
}

.welcome-container {
  max-width: 558px;
}

.reset-search {
  color: #5ca8ff;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #7cb9ff;
  }
}

.create-wf {
  width: 100%;
  &-trigger {
    margin: 2rem 0 1.5rem 0;
    display: flex;
    flex-direction: column;
    .description {
      color: #c5c5c5;
      font-weight: 300;
      font-size: 0.75rem;
      margin-bottom: 1.2rem;
    }
  }
}

.modal {
  &-content__32px {
    padding: 32px 32px 0px 32px;
  }
  &-header__32px {
    padding: 32px 0px 0px 32px;
  }
}

.workflows-refresh-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border-bottom: 1px solid #171717;
  background-color: #171717;
  &:hover {
    background-color: #353535;
  }
  position: absolute;
  right: 0px;
  top: 0px;
}

.runbook-tabs .wf-refresh-tooltip {
  width: 8rem;
  height: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;
  color: #171717;
  font-size: 12px;
  font-weight: 400;
}
