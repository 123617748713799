.input-panel-enter {
  opacity: 0.01;
  transform: scaleX(1) translateY(50vh);
}

.input-panel-enter.input-panel-enter-active {
  opacity: 1;
  transform: scaleX(1) translateY(0);
  transition: all 500ms ease-in-out;
}

.input-panel-leave {
  opacity: 1;
  transform: scaleX(1) translateY(0);
}

.input-panel-leave.input-panel-leave-active {
  opacity: 01;
  transform: scaleX(1) translateY(10px);
  transition: all 1ms ease-in-out;
}

.node-panel-enter {
  opacity: 0.01;
  transform: translateX(-100vw);
}

.node-panel-enter.node-panel-enter-active {
  opacity: 1;
  transform: scaleY(1) translateX(0);
  transition: all 500ms ease-in-out;
}

.node-panel-leave {
  transform: scaleY(1) translateX(0);
  opacity: 1;
}

.node-panel-leave.node-panel-leave-active {
  opacity: 01;
  transform: scaleY(1) translateX(10px);
  transition: all 1ms ease-in-out;
}
