@import "../../../scss/variables.scss";

.confirm-cover {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  z-index: 1000;
}

.confirm-container {
  position: relative;
  margin: 0 auto;
  margin-top: 15%;
  width: 700px;
  height: 300px;
  background: #282828;
  box-sizing: border-box;
  color: #f3f3f3;
}

.confirm-message-container {
  min-height: 250px;
  padding: 16px;
}

.confirm-message {
  padding: 20px 0px 0px 0px;
}

.confirm-requirement {
  padding-top: 25px;
}

.confirm-heading-container {
  display:flex;
}

.confirm-heading {
  flex:1;
  font-size: $fontSizeHeading;
  line-height: 20px;
  font-weight: 400;
}

.confirm-close {
  padding: 0.75rem;
  background-image: url("../../../assets/images/icons/icon-close.svg");
  background-position: 50% 50%;
  background-size: 10px 10px;
  background-repeat: no-repeat;
  -webkit-transition: all 110ms ease;
  transition: all 110ms ease;
  max-width: 100%;
  cursor: pointer;

  &:hover {
    background-color: #353535;
  }

  &:active {
    background-color: #565656;
  }
}
.confirm-footer {
  position: absolute;
  width: 100%;
  padding: 0;
  display: flex;
  bottom: 0;
}

.cancel-button {
  width: 50%;
  border-width: 0px;
  background-color: #787878;
  color: #fff;
  height: 4rem;
  max-width: none;
  margin: 0rem;
  padding-left: 16px;
  -webkit-box-flex: 1;
  cursor: pointer;
  &:hover {
    background-color: #585858;
  }
}

.confirm-button {
  width: 50%;
}

.delete-button {
  width: 50%;
  border-width: 0px;
  background-color: #fb4b53;
  color: #fff;
  height: 4rem;
  max-width: none;
  margin: 0rem;
  padding-left: 16px;
  cursor: pointer;
  -webkit-box-flex: 1;
  &:hover {
    background-color: #f8343e;
  }
}
