//Formats Inputs For Mobile
input[type="text"],
input[type="email"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input {
  -webkit-tap-highlight-color: transparent;
}
input {
  border-radius: 0;
}
//End Mobile Styling

.signup {
  &-container {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: stretch;
  }
  &-left {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 600px;
    height: 100vh;
    background: #282828;
    flex: 1 1 0%;
    margin: auto 0px;
  }
  &-right {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: center;
    align-items: center;
    flex: 1 1 0%;
    background: linear-gradient(180deg, #171717, transparent),
      radial-gradient(
        circle farthest-side at 100% 100%,
        #4e2399,
        #44279a 12%,
        #1f2978 24%,
        #171717
      );
  }
  &-card {
    display: flex;
    flex-direction: column;
    max-width: 520px;
    min-width: 400px;
    height: 100vh;
    display: flex;
    position: relative;
    justify-content: center;
    &-header {
      color: #fff;
      font-size: 1.875rem;
      line-height: 2.675rem;
      font-weight: 400;
      letter-spacing: 0.32px;
      margin-bottom: 2rem;
    }
  }

  &-form {
    &-submit-btn {
      height: 80%;
      justify-content: center;
      padding: 0px;
    }
    &-input {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;
      width: 100%;
      &-label {
        font-size: 0.75rem;
        margin-bottom: 0.5rem;
      }
      &-text {
        height: 2.5rem;
        width: -webkit-fill-available;
        padding: 0px 1rem;
        border-style: none none solid;
        background-color: #3d3d3d;
        box-shadow: inset 0 0 0 2px transparent;
        color: #f3f3f3;
        font-weight: 400;
        font-size: 0.8rem;
        &:focus {
          box-shadow: inset 0px 0px 0px 2px #f3f3f3;
          border-color: #282828;
        }
      }
    }
    &-navigator {
      &-link {
        color: #bebebe;
        font-size: 14px;
        cursor: pointer;
        text-decoration: underline;
        font-weight: 400;
        &:visited {
          text-decoration: underline;
        }
        &:link {
          text-decoration: underline;
        }
      }
      &-container {
        margin-top: 1rem;
      }
    }
  }
}

.terms-conditions {
  display: flex;
  align-items: center;
  margin-bottom: 0.2rem;
}

.three-dots {
  width: 50%;
  position: absolute;
  top: 3rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem 0px;
}

.dot {
  &-outer {
    width: 33.33%;
    height: 4px;
    margin-right: 5px;
    justify-content: center;
    align-items: center;
    background-color: #6f6f6f;
  }

  &-active {
    width: 100%;
    height: 4px;
    background-color: #f3f3f3;
  }
}

.activation-code-helper {
  margin-bottom: 2rem;
  line-height: 30px;
  &-highlight {
    color: #fff;
  }
}

.border-red {
  border: 1px solid #fb4b53;
}

.h-18 {
  height: 18px;
}

.agree-check {
  height: 16px;
  width: 16px;
}

.marketing-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  max-width: 400px;
}

.signup-message {
  font-size: 18px;
  color: #f3f3f3;
  font-family: ibmplexsans-semibold;
  line-height: 30px;
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 1rem;
}
.signup-logo {
  margin-left: 1.5rem;
}
.signup-point {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  font-size: 15px;
  line-height: 20px;
  color: #f3f3f3;
  margin-top: 1.5rem;
  max-width: 400px;
  font-family: ibmplexsans-light;
}
.message-point {
  margin-right: 1rem;
  margin-top: 2px;
}
//Prevents screen zooming into form input on mobile
@media only screen and (max-width: 991px) {
  input {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 991px) {
  .signup-container {
    flex-direction: column-reverse;
    justify-content: flex-start;
  }
  body {
    overscroll-behavior: auto;
    overflow-y: auto;
  }
  .three-dots {
    top: 1.5rem;
  }
  .signup-left {
    min-width: 100vw;
    height: auto;
  }
  .signup-right {
    min-width: 100vw;
    height: 30vh;
  }
  .signup-point {
    display: none;
  }
  .signup-logo {
    margin-top: 4rem;
    width: 60%;
  }
  .signup-message {
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
    font-size: 16px;
    width: 90%;
  }
  .marketing-content {
    align-items: center;
  }
  .signup-logo {
    margin-left: 0;
  }
  .signup-card {
    min-width: 0;
  }
}
