.task {
  &-input {
    &.mb-4 {
      margin-bottom: 2rem;
    }
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  &-wrapper {
    display: flex;
    .overflow-menu-icon {
      top: unset;
    }
    .rdt_TableCell {
      padding: 1.5rem 0.5rem 1.5rem 0.9rem;
    }
    .rdt_TableRow {
      min-height: 50px;
    }
    .rdt_TableCol_Sortable {
      width: 100%;
      display: flex;
      justify-content: space-between;
      span {
        margin-right: 5px;
      }
      &:hover {
        background-color: #555;
      }
    }
  }
}

.sidebar-list {
  list-style: none;
}

.task-panel {
  min-height: 500px;
  width: 70%;
}

.page-tick-icon {
  width: 16px;
  height: 16px;
  margin-right: 15px;
  transform: translate(0%, 20%);
  background-size: contain;
  background-repeat: no-repeat;
}

.visibility-hidden {
  visibility: hidden;
}

.w-90 {
  width: 90%;
  max-width: 1100px;
}

.button-tertiary {
  width: 230px;
  color: #78a9ff;
  background: transparent;
  border-color: transparent;
  position: absolute;
  left: 0;
  &:hover {
    background: #353535;
    border-color: transparent;
    color: #a6c8ff;
  }
  &:active {
    background: #3d3d3d;
    border-color: #f3f3f3;
  }
}

.btn-new-type {
  height: 40px;
}

.text-area {
  border-bottom: 1px solid #8c8c8c;
}
