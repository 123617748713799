@import "../../scss/variables.scss";

$controller-color: $lightblue;
$custom-color: $green;
$connector-color: $orange;
$default-color: $soft-white;
.snippet {
  &-tooltip-container {
    width: 13rem;
    height: 4rem;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    padding: 0 0 0 8px !important;
    color: #171717 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
  }
  &-grid {
    display: grid;
    grid-auto-flow: row;
    grid-auto-columns: 81px;
    grid-auto-rows: 81px;
    grid-column-gap: 1px;
    grid-row-gap: 1px;
    -ms-grid-columns: 81px 82px 81px;
    grid-template-columns: 81px 82px 81px;
    -ms-grid-rows: 81px 81px;
    grid-template-rows: 81px;
    background-color: #2c2c2c;
  }
  &-block {
    display: flex;
    flex-wrap: wrap;
    width: 290px;
    background-color: #2c2c2c;
  }
  &-wrap-new {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #555555;
    -webkit-transition: all 70ms ease;
    transition: all 70ms ease;
    color: #f3f3f3;
    font-size: $fontSizeMediumLarge;
    line-height: 15px;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    text-align: left;
    cursor: -webkit-grab;
    cursor: grab;
    border-bottom: 1px solid #3d3d3d;
    transition: all 0s;

    &:hover {
      background: #616161;
      background-image: url("../../assets/images/icons/drag-icon.svg");
      background-repeat: no-repeat;
      background-position: right 16px center;
    }
  }
  &-wrap-new-disconnected {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #555;
    color: hsla(0, 0%, 95.3%, 0.7);
    font-size: $fontSizeMediumLarge;
    line-height: 15px;
    height: 42px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    text-align: left;
    cursor: default;
    border-bottom: 1px solid #3d3d3d;
  }
  &-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #3d3d3d;
    -webkit-transition: all 70ms ease;
    transition: all 70ms ease;
    color: hsla(0, 0%, 95.3%, 0.7);
    font-size: $fontSizeMediumLarge;
    line-height: 15px;
    text-align: center;
    cursor: -webkit-grab;
    cursor: grab;

    &:hover {
      background: #666;
    }
  }
  &-name {
    bottom: 0px;
    display: block;
    padding-left: 10px;
    font-weight: 300;
    width: 100%;
    text-align: left;
    padding-right: 4px;
    font-size: $fontSizeMediumLarge;
    line-height: 20px;
    position: relative;
    width: 100%;
    height: 46%;
    padding-top: 0px;
    justify-content: center;
    align-items: center;
    &.radio__xl {
      height: 30%;
      padding-bottom: 0px;
      padding-left: 16px;
      justify-content: flex-start;
      position: absolute;
      display: flex;
    }
  }
}

.snippet-icon {
  position: relative;
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background: #f3f3f3;
  &.radio__xl {
    height: 100%;
    width: 100%;
    padding-top: 16px;
    padding-right: 16px;
    padding-left: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    opacity: 1;
  }
}

.opaque {
  opacity: 0.4;
}

.snippet-icon-image {
  mask-position: 0% 50%;
  mask-size: contain;
  mask-size: contain;
  mask-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  height: 18px;
  width: 18px;
}

.snippet-icon-default {
  mask-image: url("../../assets/images/snippets/icon-default.svg");
}

.snippet-success {
  background-image: url("../../assets/images/snippets/icon-success.svg");
}

.snippet-stop {
  background-image: url("../../assets/images/snippets/icon-stop.svg");
}

.snippet-fail {
  background-image: url("../../assets/images/snippets/icon-fail.svg");
}

.snippet-custom {
  mask-image: url("../../assets/images/snippets/icon-custom.svg");
  background-color: $custom-color;
}

.snippet-conditional {
  background-image: url("../../assets/images/snippets/icon-conditional.svg");
}

.snippet-webhook {
  background-image: url("../../assets/images/snippets/icon-webhook.svg");
}

.snippet-json {
  background-image: url("../../assets/images/snippets/icon-jsonpath.svg");
}

.snippet-wait {
  background-image: url("../../assets/images/snippets/icon-wait.svg");
}

.snippet-pause {
  background-image: url("../../assets/images/snippets/icon-pause.svg");
}

.connector-slack {
  background-image: url("../../assets/images/snippets/icon-slack-logo.svg");
}

.connector-jira {
  background-image: url("../../assets/images/snippets/icon-jira-logo.svg");
}

.snippet-eks-actions {
  background-image: url("../../assets/images/snippets/icon-eks.svg");
}

.snippet-eks {
  background-image: url("../../assets/images/snippets/icon-eks.svg");
}

.snippet-email {
  background-image: url("../../assets/images/snippets/icon-send.svg");
}

.snippet-kubectl {
  background-image: url("../../assets/images/snippets/icon-eks.svg");
}

.connector-datadog {
  background-image: url("../../assets/images/snippets/icon-datadog-logo.svg");
}

.connector-ansible {
  background-image: url("../../assets/images/snippets/icon-ansible.svg");
}

.connector-instana {
  background-image: url("../../assets/images/snippets/icon-instana.svg");
}

.connector-cloudwatch {
  background-image: url("../../assets/images/snippets/icon-cloudwatch.svg");
}

.connector-cloudfront {
  background-image: url("../../assets/images/snippets/icon-cloudfront.svg");
}

.connector-athena {
  background-image: url("../../assets/images/snippets/icon-athena.svg");
}

.connector-dynamodb {
  background-image: url("../../assets/images/snippets/icon-dynamodb.svg");
}

.connector-ebs {
  background-image: url("../../assets/images/snippets/icon-ebs.svg");
}

.connector-rds {
  background-image: url("../../assets/images/snippets/icon-rds.svg");
}

.connector-iam {
  background-image: url("../../assets/images/snippets/icon-iam.svg");
}

.connector-route53 {
  background-image: url("../../assets/images/snippets/icon-route53.svg");
}

.connector-ec2 {
  background-image: url("../../assets/images/snippets/icon-ec2.svg");
}

.connector-elb {
  background-image: url("../../assets/images/snippets/icon-elb.svg");
}

.connector-lambda {
  background-image: url("../../assets/images/snippets/icon-lambda.svg");
}

.connector-ssh {
  background-image: url("../../assets/images/snippets/icon-ssh.svg");
}

.connector-jenkins {
  background-image: url("../../assets/images/snippets/icon-jenkins.svg");
}

.connector-cloudformation {
  background-image: url("../../assets/images/snippets/icon-cloudformation.svg");
}

.snippet-trustedadvisor {
  background-image: url("../../assets/images/snippets/icon-trustedadvisor.svg");
}

.connector-pagerduty {
  background-image: url("../../assets/images/snippets/icon-pagerduty.svg");
}

.connector-opsgenie {
  background-image: url("../../assets/images/snippets/icon-opsgenie.svg");
}

.connector-splunk {
  background-image: url("../../assets/images/snippets/icon-splunk.svg");
}
.connector-splunk-on-call {
  background-image: url("../../assets/images/snippets/icon-victorops.svg");
}

.connector-humio {
  background-image: url("../../assets/images/snippets/icon-humio.svg");
}

.action-splunk-on-call {
  background-image: url("../../assets/images/snippets/icon-victorops.svg");
}
.connector-python {
  background-image: url("../../assets/images/snippets/icon-python.svg");
}

.connector-s3 {
  background-image: url("../../assets/images/snippets/icon-s3.svg");
}

.connector-twilio {
  background-image: url("../../assets/images/snippets/icon-twilio.svg");
}

.snippet-terraform {
  background-image: url("../../assets/images/snippets/icon-terraform-logo.svg");
}

.snippet-container {
  background-image: url("../../assets/images/snippets/icon-container.svg");
}

.snippet-http {
  background-image: url("../../assets/images/snippets/icon-http.svg");
}

.snippet-pulumi {
  background-image: url("../../assets/images/snippets/icon-pulumi.svg");
}

.snippet-action {
  background-image: url("../../assets/images/snippets/icon-aws-logo.svg");
}

.snippet-loop {
  background-image: url("../../assets/images/snippets/icon-loop.svg");
}

.snippet-aws_health {
  background-image: url("../../assets/images/snippets/icon-aws_health.svg");
}

.snippet-sumologic {
  background-image: url("../../assets/images/snippets/icon-sumologic.svg");
}

.snippet-approval {
  background-image: url("../../assets/images/snippets/icon-approval.svg");
}

.snippet-servicenow {
  background-image: url("../../assets/images/snippets/icon-servicenow.svg");
}

.snippet-elasticsearch {
  background-image: url("../../assets/images/snippets/icon-elasticsearch.svg");
}

.snippet-transformer {
  background-image: url("../../assets/images/snippets/icon-transformer.svg");
}

.snippet-prometheus {
  background-image: url("../../assets/images/snippets/icon-prometheus.svg");
}

.snippet-manual {
  background-image: url("../../assets/images/snippets/icon-manual.svg");
}

.trigger-node {
  background-color: #e34da7;
}

.constant-autocomplete-list {
  height: auto;
  border: 1px solid #353333;
  width: calc(100% - 30px);
  padding: 5px;
  z-index: 1111;
  position: absolute;
  background: #2c2c2c;
  border-top: none;
}
.constant-autocomplete-list ul {
  padding: 5px;
  margin: 0;
  border-bottom: 1px solid #161616;
}
.constant-autocomplete-list ul:last-child {
  border-bottom: none;
}
.constant-autocomplete-list ul:hover {
  background-color: #4d4b4b;
}
.constant-autocomplete-list li {
  list-style-type: none;
  margin-bottom: 5px;
  font-size: $fontSizeMediumSmall;
  cursor: pointer;
}

.node-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  height: 20px;
  width: 20px;
  mask-size: contain;
  mask-position: 50% 50%;
  mask-repeat: no-repeat;
}

.terraform-variable {
  margin-bottom: 1rem;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    .invalid-json {
      color: $warnBackground;
    }
    span {
      font-size: 0.75rem;
    }
  }
}

.snippet-disconnected {
  opacity: 0.4 !important;
  &__text {
    padding-left: 12px;
    opacity: 0.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
