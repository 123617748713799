.warning {
  &-container {
    width: 100%;
    display: flex;
    max-width: none;
    margin-top: 2rem;
    margin-bottom: 0rem;
    border-left: 3px solid #fed23a;
    background-color: #3d3d3d;
    height: auto;
    min-height: 3rem;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
    flex-direction: row;
    &__inner-wrap {
      display: flex;
      align-items: center;
    }
    &-cross {
      cursor: pointer;
      background-color: transparent;
      border: none;
      color: #f3f3f3;
      margin-right: 8px;
      padding: 8px 4px;
      &:hover {
        background-color: #524f4f;
      }
      &:focus {
        box-shadow: inset 0px 0px 0px 2px #f3f3f3;
      }
    }
  }
  &-text {
    color: #f3f3f3;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.16px;
  }
  &-link {
    cursor: pointer;
    color: #5ca8ff;
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 400;
    letter-spacing: 0.16px;
    text-decoration: none;
    padding-left: 2px;
    font-family: IBMplexsans-Medium, sans-serif;
    &:hover {
      color: #7cb9ff;
      text-decoration: none;
    }
  }
}
