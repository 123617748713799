@import "../../../../scss/variables.scss";

.bool {
  position: relative;
  &-container {
    cursor: pointer;
    &-arrow {
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 38px;
      background-image: url(/static/media/arrow__down.dc4e20a5.svg);
      background-position: 50% 50%;
      background-size: 12px;
      background-repeat: no-repeat;
      color: #fff;
      filter: invert(100%);
      right: 16px;
    }
    &-close {
      position: absolute;
      width: 1rem;
      height: 1rem;
      top: 38px;
      background-image: url("../../../../assets/images/icons/icon-close.svg");
      background-position: 50% 50%;
      background-size: 10px;
      background-repeat: no-repeat;
      color: #fff;
      right: 55px;
    }
  }
  &-overlay {
    position: fixed;
    height: 100%;
    width: 383px;
    right: 0;
    top: 0;
    z-index: 1000;
  }
  &-dropdown {
    z-index: 1001;
    right: 0px;
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 250px;
    width: 100%;
    background-color: #555;
    color: #f3f3f3;
    font-size: 14px;
    font-weight: 400;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
    border: 1px solid #f3f3f3;
    &-option {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0px 8px 0px 16px;
      cursor: pointer;
      position: relative;
      &:hover {
        background-color: #5e5e5e;
      }
      &-selected {
        background-color: #6f6f6f;
        &:hover {
          background-color: #6f6f6f;
        }
      }
      &-selected-check {
        position: absolute;
        right: 12px;
        width: 16px;
        height: 16px;
        margin-left: 7px;
        flex: 0 0 auto;
        background-image: url(https://assets.website-files.com/5d7fdec85e3a80dddda38d90/5d83bcc9a185122e1566a7fe_checkmark-white.svg);
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}
