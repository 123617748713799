.up {
  &-header {
    justify-content: space-between;
    align-items: center;
    width: 100%;
    &-label {
      color: #fff;
      font-size: 1.875rem;
      line-height: 2.675rem;
      font-weight: 400;
      letter-spacing: 0.32px;
    }
    &-btn {
      display: flex;
      width: 8rem;
      height: 2.5rem;
      border: none;
      background-color: #6f6f6f;
      color: #fff;
      font-weight: 400;
      padding-left: 16px;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: #606060;
      }
      &:focus {
        box-shadow: inset 0px 0px 0px 2px #f3f3f3;
      }
    }
  }
  &-tabs {
    &-container {
      width: 100%;
      display: flex;
      margin: 2rem 0;
      border-bottom: 2px solid #252525;
      height: 3rem;
    }
    &-tabheader {
      height: auto;
      display: flex;
      padding: 0.5rem 1rem;
      align-items: center;
      width: 10rem;
      cursor: pointer;
      &:hover {
        border-bottom: 2px solid #6f6f6f;
        color: #f3f3f3;
      }
    }
    &-selected {
      border-bottom: 2px solid #6300ff;
      color: #fff;
      font-weight: 400;
    }
  }
  &-info {
    width: 100%;
    margin-bottom: 4rem;
    &-card {
      display: flex;
      flex-direction: column;
      background: #282828;
      padding: 1rem;
      width: 60%;
      &-header {
        color: #fff;
        font-size: 1.875rem;
        line-height: 2.675rem;
        font-weight: 400;
        letter-spacing: 0.32px;
      }
    }

    &-form {
      &-submit-btn {
        height: 70%;
        padding: 0;
        justify-content: center;
        min-width: 100%;
      }
      &-input {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;
        &-label {
          font-size: 0.75rem;
          margin-bottom: 0.5rem;
          font-weight: 400;
          letter-spacing: 0.32px;
        }
        &-text {
          width: 60%;
          height: 2.5rem;
          padding: 0px 1rem;
          border-style: none none solid;
          background-color: #3d3d3d;
          box-shadow: inset 0 0 0 2px transparent;
          color: #f3f3f3;
          font-weight: 400;
          font-size: 0.75rem;
        }
      }
    }
  }
}

.form-footer-submit {
  max-width: 40%;
}

.ml-n2 {
  margin-left: -2rem;
}

.m-0 {
  margin: 0;
}

.pwd-strength-wrapper {
  width: 401px;
}

.w-a {
  width: -webkit-fill-available;
}

.tabs-menu-border {
  border-bottom: 2px solid #252525;
}

.account {
  &-details {
    width: 60%;
    padding: 16px;
    background-color: #282828;
    margin-right: 24px;
    margin-bottom: 3rem;
    &-plan-header {
      margin-bottom: 2rem;
    }
    &-usage {
      display: flex;
      width: 100%;
      &-plan-meta {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 25%;
      }
      &-plan-benefits {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        &__plan-checklist {
          display: flex;
          margin-top: 10px;
          margin-bottom: 0px;
          font-size: 13px;
          &__checkmark {
            width: 16px;
            height: 16px;
            margin-right: 8px;
            background-image: url("../../../assets/images/icons/icon-tick.svg");
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
          }
        }
      }
      &__margin-top {
        margin-top: 2rem;
      }
    }

    &-plan-usage {
      display: flex;
      flex-direction: column;
      margin-top: 2rem;
      padding: 1.5rem;
      align-items: flex-start;
      border-style: solid;
      border-width: 1px;
      border-color: #3d3d3d;
      background-color: #3d3d3d;
      &__usage-wrap {
        display: flex;
        flex-direction: column;
        width: 100%;
        &__top {
          display: flex;
          margin-top: 1.5rem;
          justify-content: space-between;
          width: 100%;
        }
      }
      &__bar-wrap {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 10px;
        margin-top: 8px;
        align-items: center;
        border-style: solid;
        border-width: 1px;
        border-radius: 5px;
        border-color: #656565;
        &__bar-use {
          width: 50%;
          height: 100%;
          border-radius: 5px;
          background: linear-gradient(45deg, #5ca8ff, #9363ff);
          &.red {
            background-color: #ff5b5b;
          }
        }
      }
    }
  }
  &-actions {
    width: 40%;
    padding: 16px;
    background-color: #282828;
    max-height: 17rem;
    &-btn {
      display: flex;
      width: 100%;
      height: 2.5rem;
      border: none;
      background-color: #6f6f6f;
      color: #fff;
      font-weight: 400;
      justify-content: space-around;
      align-items: center;
      cursor: pointer;
      margin-bottom: 1.5rem;
      padding: 1.2rem;
      &__primary {
        background-color: #6300ff;
      }
      &:hover {
        opacity: 0.9;
      }
      &:focus {
        box-shadow: inset 0px 0px 0px 2px #f3f3f3;
      }
    }
  }
}

.meta-title {
  color: #bebebe;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.16px;
  opacity: 0.8;

  &__bold {
    opacity: 1;
    color: #f3f3f3;
    font-weight: 500;
  }
  &__fw {
    max-width: none;
    color: #bebebe;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 0.16px;
    opacity: 0.8;
  }
}
