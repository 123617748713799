.tags-wrapper {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  align-items: center;

  .tag-custom {
    color: #bebebe;
    display: flex;
    height: 32px;
    margin-right: 12px;
    margin-bottom: 12px;
    padding-right: 12px;
    padding-left: 12px;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    border-radius: 16px;
    background-color: #4c4c4c;
    font-size: 13px;
    cursor: pointer;
  }

  .tag-selected {
    background-color: rgb(243, 243, 243);
    color: rgb(23, 23, 23);
  }

  .check-mark {
    width: 16px;
    height: 14px;
    margin-right: 6px;
    flex: 0 0 auto;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
  }
}
