.node-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
  .node {
    width: 99px;
    height: 98px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #606060;
    background-color: #3d3d3d;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 4px;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;

    .title {
      font-size: 12px;
      font-weight: 300;
      margin-top: 5px;
      text-align: center;
    }
    .image {
      width: 36px;
      height: 36px;
      background-size: contain;
      background-repeat: no-repeat;
      background-color: #f3f3f3;
      border: 5px solid #f3f3f3;
      border-radius: 3px;
      margin-right: 0;
    }
    &.active {
      border: 2px solid #78a9ff;
      background-color: rgba(175, 204, 255, 0.12);
    }
    &.disabled-node {
      opacity: 0.3;
      cursor: unset;
    }
  }
}

.info-icon {
  width: 16px;
  height: 16px;
  margin: 4px;
  background-image: url("../../../assets/images/icons/icon-unconfigured.svg");
  background-position: 50% 50%;
  background-size: 14px 14px;
  background-repeat: no-repeat;
  position: absolute;
  background-color: transparent;
  border: 0px;
  right: 2px;
  top: 2px;
  opacity: 1;
}
.info-section {
  display: flex;
  .info-icon {
    position: unset;
  }
  .text {
    color: #c5c5c5;
    font-weight: 300;
    font-size: 0.75rem;
    margin-bottom: 1.2rem;
    padding: 3px 0 0 3px;
  }
}
