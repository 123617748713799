.input-view {
  background-color: #555;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 40px;
  min-height: 40px;
  margin-bottom: 1px;
  padding: 0 12px;
  padding-left: 16px;
  font-size: 12px;
  color: #f3f3f3;
  border-width: 2px 2px 1px;
  border-color: #000 #000 #3d3d3d;
  font-family: Ibmplexmono, sans-serif;
  &:hover {
    background-color: #616161;
  }
  &-container {
    width: 100%;
    display: flex;
    &-left {
      width: 80%;
      div {
        width: 96%;
        margin-right: 2px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &-right {
      width: 20%;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      &-delete,
      &-edit {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        opacity: 0.7;
        transition: opacity 70ms ease;
        cursor: pointer;
        &:hover {
          opacity: 1;
        }
        & > img {
          height: 12px;
        }
      }
    }
  }
}
