@import "../../scss/variables.scss";

.stat {
  &-container {
    display: flex;
    flex-direction: column;
    height: 3.5rem;
    justify-content: space-between;
    align-items: center;
  }
  &-label {
    font-size: $fontSizeMediumLarge;
    color: #bebebe;
  }
  &-value {
    font-size: 35px;
    line-height: 30px;
  }
}

.statline {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 50px 0px;
  padding-right: 1rem;
}

.title-bar:hover {
  border-bottom: 1px solid #8c8c8c;
}

.select-container {
  &-expanded > .li-option:first-child {
    margin-top: 16px;
  }
  &-expanded > .li-option:last-child {
    margin-bottom: 16px;
  }
}

.select-menu ul {
  background: #222;
  cursor: pointer;
}

.li-option {
  color: #f3f3f3;
}

.executions {
  &-status-filter {
    width: 16rem;
  }

  &-container {
    display: flex;
    flex-direction: column;
    padding: 2.6rem 0 2rem 1rem;
    color: #dfdfdf;
  }
  &-refresh-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    height: 40px;
    width: 40px;
    cursor: pointer;
    border-bottom: 1px solid #171717;
    background-color: #171717;
    &:hover {
      background-color: #353535;
    }
  }
  &-clear-filters {
    cursor: pointer;
    padding: 35px 15px;
    text-decoration: underline;
    color: #bebebe;
    display: inline-table;
    margin-left: 15px;
  }
  &-chart {
    min-height: 200px;
    display: grid;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    grid-template-columns: 1fr 4fr;
    grid-column-gap: 2px;
    -webkit-column-gap: 2px;
    column-gap: 2px;
    font-size: 14px;
    &-child {
      padding: 10px;
      padding-left: 2px;
      box-sizing: border-box;
    }
  }
}
