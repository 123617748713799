.sh {
  &-container {
    font-size: 16px;
    font-weight: 400;
    padding-left: 12px;
    color: #5ca8ff;
    display: flex;
    padding: 20px 16px;
    border-bottom: 1px solid #292929;
  }
  &-label {
    color: #f3f3f3;
    font-size: 16px;
    line-height: 20px;
    margin-top: 2px;
    font-family: IBMPlexSans-Medium, sans-serif;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-id {
    color: #9bbfff;
    font-size: 13px;
    line-height: 22px;
    font-weight: 300;
    font-family: IBMPlexSans-Regular, sans-serif;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-icon {
    background: #f3f3f3;
    height: 36px;
    width: 36px;
    display: flex;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    & > img {
      height: 22px;
      width: 22px;
    }
  }
}
