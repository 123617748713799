.collaborations-incidents {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 2px solid #3d3d3d;
  .wrapper {
    width: 40%;
    display: flex;
    flex-direction: column;
    .save-btn {
      height: 40px;
      width: 125px;
    }
  }
  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.collaborations-options {
  padding: 24px 24px 8px;
  border: 2px solid #3d3d3d;
  margin-top: 24px;
  .wrapper {
    width: 40%;
    display: flex;
    flex-direction: column;
    .save-btn {
      height: 40px;
      width: 125px;
    }
  }
  &.disable {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .title-wrapper {
    margin-bottom: 10px;
    background-color: #282828;
    .header {
      display: flex;
      justify-content: space-between;
      .left {
        display: inline-block;
        flex-direction: column;
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          max-width: 100%;
          vertical-align: middle;
          display: inline-block;
        }
        .title {
          font-size: 1rem;
          line-height: 2.2rem;
          font-weight: 300;
          color: #f3f3f3;
          vertical-align: middle;
        }
        .description {
          font-size: 14px;
          line-height: 20px;
          font-weight: 300;
          color: hsla(0, 0%, 95.3%, 0.8);
        }
      }
      .right {
        display: flex;
        align-items: center;
        .toggle {
          display: flex;
          justify-content: space-between;
          width: 92px;
          .status {
            color: #bebebe;
            font-size: 14px;
            margin-top: 4px;
          }
        }
      }
    }
  }
}

.toggle-container {
  position: absolute;
  margin-top: 44px;
  width: 100%;
  z-index: 2;
  display: flex;
  flex-direction: row-reverse;
  right: 60px;
  .toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 9rem;
    .status {
      color: #bebebe;
      font-size: 14px;
      margin-top: 4px;
    }
  }
}

.save-btn {
  height: 40px;
  width: 125px;
  margin-top: 2rem;
}
